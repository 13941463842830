.top-review-outer {
    margin-top: -15px;
}

.top-review-shop-card {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 0 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 0 4px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 4px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.top-review-shop-card>.shop-card-inner {
    padding: 18px;
    flex-grow: 1;
}

.top-review-shop-image-section {
    cursor: pointer;
    position: relative;
}

.top-review-shop-image-section>.shop-image>img {
    height: 200px;
    width: 100%;
}

.shop-rating {
    position: absolute;
    bottom: 9px;
    left: 6px;
}

.card-rating {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 3px 6px 4px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 130px;
    max-height: 25px;
    margin-bottom: 6px;
}



.card-rating__value {
    line-height: 1 !important;
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    color: #333;
    margin-left: 5px;
    font-weight: 700 !important;
}

.top-review-shop-content-section {
    margin-top: 12px;
}

.card-main__heading {
    cursor: pointer;
    font-size: 15px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: normal;
    overflow: hidden;
    font-weight: 700;
    line-height: 1.2 !important;
}

.top-review-shop-content-section>.card-main__heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-review-shop-content-section>.card-main__heading:hover{
    color: var(--sura-text-color);
}

.card-main-address-section {
    font-size: 14px;
    line-height: 1.2;
    vertical-align: top;
    color: #999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-main-footer-section {
    background-color: #f6f6f6;
    ;
    padding: 0 18px;
}

.btn-section {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    color: var(--sura-text-color) !important;
    ;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}

.btn-section .btn-text {
    position: relative;
    transition: all 0.3s ease;
}

.btn-section:hover .btn-text::after {
    content: '➔';
    position: absolute;
    right: -35px;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 19px;
    font-weight: bold;
    opacity: 1;
    right: 0px;
    top: -2px;
}

.btn-section:hover .btn-text {
    padding-right: 35px;
}

.box {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-main__footer {
    margin-top: auto;
}

.top-review-shop-row>.col-lg-3,
.top-review-shop-row>.col-sm-12,
.top-review-shop-row>.col-md-4 {
    display: flex;
    flex-direction: column;
}

.top-review-shop-rating-star>.ant-rate>.ant-rate-star>div>div>.anticon {
    font-size: 13px !important;
}

@media (max-width:768px) {
    .top-review-shop-image-section>.shop-image>img {
        width: 100%;
        height: auto;
        object-fit: fill;
        aspect-ratio: 1/1;
        height: 300PX;
    }
}

@media (max-width:450px) {
    .top-review-shop-image-section>.shop-image>img {
        height: 195px;
    }
}