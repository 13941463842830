a {
    outline: none;
}
.review-rating-Span{
  margin-left: -12px;

}

.AccountSettingHeader-container {
  padding: 0 5px !important;
}

.user-bookings-tab-active, .user-bookings-tab-active:hover {
  background-color: var(--hotpink-border-color) !important;
  color: #fff !important;
}