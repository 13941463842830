
body {
background-color: var(--emty-card-body-bg-color);
    font-family: 'Arial' !important;
}
.mt-100{
   margin-top:100px;
}
.emty-card{
    margin-bottom: 50px;
    min-height: 60vh;
}
.card {
    border: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 8px;
    -webkit-box-shadow: 1px 5px 24px 0 var(--emty-card);
    box-shadow: 1px 5px 24px 0 var(--emty-card);
}
.card .card-header {
    background-color:var(--white-btn-bg-color);
    border-bottom: none;
    padding: 24px;
    border-bottom: 1px solid #f6f7fb;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card .card-body {
    padding: 30px;
    background-color: transparent;
}
.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
    background-color: var(--head-text)!important;
    border-color: var(--head-text)!important;
}
.fa-empty-card{
    font-size: 18px;
    color: var(--black-color);
}