.payment-done-continer {
    text-align: center;
    padding: 40px 0;
    
  }
.payment-done-continer button{
    border-radius: 0px!important;
}
.payment-done-page{
    border-radius: 200px;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    outline: 9px solid green;
    stroke-dasharray: 76;
    stroke-dashoffset: 76;
    animation: draw 1s forwards;
}
.payment-done-continer h1 {
    color: green;
    font-family: "Arial";
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
 .payment-done-continer p {
    color: #404F5E;
    font-family: "Arial";
    font-size:20px;
  }
.payment-done-continer i {
    color: green;
    font-size: 112px;
    line-height: 200px;
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: scale(0);
    animation: checkmarkAnimation 0.3s ease-in-out forwards;
}
@keyframes checkmarkAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
.payment-done-continer .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}
@media (max-width:320px){
    .payment-done-continer .card{
        padding: 40px;
    }
}

@media (min-width:500px){
    .payment-done-continer p {
    color: #404F5E;
    font-family: "Arial";
    font-size:18px;
  }
}