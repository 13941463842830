.service-modal-div {
    background: rgba(239, 239, 239, 1);
    padding: 10px 10px;
    text-align: center;
}

.service-modal-star,.service-modal-star span{
    font-size: 30px;
    color:var(--star-color);
}

.service-modal-p {
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: 0em;
}
.icon-color-grey-font{
    color: grey;
    font-size: 25px;
}
.service-modal-h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    padding: 10px 10px
}

.service-modal-button Button,.service-modal-button Button:hover {
    width: 100px;
    background: var(--bg-color) !important;
    color: white !important;
    font-weight: bold;
}

.service-modal-button {
    justify-content: center;
    padding: 10px;
}
.service-modal-start{
    color: #F8DB13;
    font-size: 27px;
}