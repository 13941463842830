.login .password-field {
  width: 100%;
}

.back-to-home {
  color: var(--black-color);;
  text-decoration: underline;
}

.back-to-home:hover {
  color: var(--sura-text-color);
}

.no-gutter {
  height: 100vh;
}

.bg-image {
  background: var(--bg-img-bg);
  background-size: cover;
  background-position: center center;
}
.solid-black{
  color: solid black;
}
.sign-in-img-div {
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.signin-form-container,
.fdfdfdf {
  padding: 50px;
  border: 1px solid var(--white-btn-bg-color);
  background: var(--white-btn-bg-color);
  align-items: center;
  justify-content: center;
}


.fav-modal label,
.fav-modal .navigate-signup {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 1.399999976158142px;
  text-align: left;
  text-transform: capitalize;
}

.fav-modal input {
  border-radius: 0;
}

.fav-modal .password-field {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.signup-form-heading2 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1.399999976158142px;
  white-space: inherit;
  line-height: 60px;
}

.signup-form-heading3 {
  margin: 10px;
  color: var(--input-field-color);
}
.modal-body-form label,.modal-body-form .navigate-signup,.modal-body-form label span{
  color: var(--input-field-color);
}
.modal-body-form {
  margin: 22px;
}

.sign-in-sign-in-btn-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.signIn-image {
  width: 50%;
  margin-top: 20% !important;
}

.signInbtn-color,
.signInbtn-color:hover {
  background: var(--bg-color);
  /* box-shadow: 0px 3px 12px 0px rgba(253, 98, 102, 0.80); */
  box-shadow: 0px 10px 15px -6px var(--sign-in-box-shadow);
  color: var(--white-text-color)!important;
  margin-top: 25px;
  display: inline-block;
  border: none;
}
.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId{
  font-family: 'Arial' !important;
  font-size: 13px!important;
  width: 128px;
}
.sign-in-form-div>div>.form-control {
  padding: 5px 11px;
}

.account a {
  cursor: pointer !important;
}

.signin-forgetPassword {
  color: var(--sura-text-color);
  font-size: 14px;
  display: flex;
  float: right;
  font-weight: 300;
  margin-top: -1.4rem;
}

.signin-forgetPassword1 {
  color: var(--sura-text-color);
  font-size: 14px;
  display: flex;
  float: right;
  font-weight: 300;
}

hr {
  width: 30rem;
}

.no-gutter input,
.no-gutter .pass {
  border-radius: 0;
}

.no-gutte .bg-image img {
  width: 50% !important;
}

.navigate-signup {
  display: flex;
  justify-content: center;
}

.navigate-signup a,
.justify__space_between a {
  color: var(--sura-text-color);
  text-decoration: none;
}
.justify__space_between span {
  color: var(--black-color);
}
.btn-social {
  display: flex;
  justify-content: center;
  padding: 1% !important;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  text-transform: capitalize;
  font-weight: 500;
  text-overflow: ellipsis;
  vertical-align: top;
}

.nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: 'Arial' !important;
}

::placeholder {
  color: var(--placeholder) !important;
}

.modal-backdrop.fade {
  opacity: 1 !important;
}

.modal-backdrop {
  backdrop-filter: blur(2.5px);
  z-index: 1000;
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: none !important;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: 0;
}

.modalPopup-body .modal-content {
  width: 82% !important;
  left: 9%;
}


@media (max-width:992px) {
  .no-gutter {
    height: auto;
  }

  .no-gutter>.inner-div>.mt-5 {
    margin-top: 0.5rem !important;
  }

  .no-gutter>.inner-div>.row>.sign-in-img-div>.signIn-image {
    margin-top: 15px !important;
  }

  .no-gutter>.inner-div>.row>.sign-in-img-div>.fa-arrow-left {
    margin-bottom: 25px;

  }

  .signin-form-container ,.signup-form-container{
    padding: 0px 20px !important;
  }

  .sign-in-img-div>.fa-arrow-left,
  .sign-in-img-div>.back-to-home {
    margin-bottom: 30px;
  }

  .signIn-image {
    margin-top: 30px !important;
  }
  .navigate-signup {
    margin-bottom: 25px;
  }
}


@media (min-width:992px) {
  .sign-in-final>.row>.col-lg-6,
  .sign-in-final>.row>.col-md-12,
  .sign-in-final>.row>.col-sm-12,
  .sign-in-final>.row>.col-xs-12 {
    display: flex;
    flex-direction: column;
  }

  .sign-in-img-div,
  .signin-form-container {
    flex: 1;
    flex-direction: column;
  }
}
.sign-in-final{
 justify-content: center;
 align-items: center;
}
@media (max-width:575px) {
  .sign-in-final>.mt-5{
    margin-top: 0rem !important;
  }
.navigate-signup{
  text-align: center !important;
}
.navigate-signup a{
  white-space: nowrap;
}
  .navigate-signup {
    display: block;
    justify-content: center;
  }
}

.sign-in-img-div>i{
  font-size: 20px;
}


@media (min-width:992px) {
  .sign-in-img-div{
    min-width: 550px; /* Fixed minimum width */
    width: 100%; /* Full width to adapt to the container */
    max-width: 90vw; /* Limits the width to 90% of the viewport width */
    margin: auto; 
  }
  
}
@media (max-width:320px) {
.signup-form-heading2{
font-size: unset;  
}
.signin-forgetPassword{
  font-size: 13px; 
}
.justify__space_between span {
  font-size: 13px; }
  .signin-formfield label{
    font-size: 15px;

  }
  .signin-formfield .navigate-signup{
    font-size: 14px;
  }
}