main {
  width: auto;
  margin-left: 60px;
}

.sidebar {
  color:var(--white-color);
  width: 20%;
  transition: all 0.5s;
}

.vendor_top_section {
  display: flex;
  padding: 16px 15px;
  justify-content: center;
}
.vendor-sidebar-logout{
  margin-bottom: 10px;
}
.vendor-sidebar-icon-logout{
  margin-top: -1px;
}
.logo {
  margin: 100px;
  font-size: 20px;
  font-weight: bold;

}

.sidebar-logout-vendor {
  position: relative;
  top: 17px;
  margin-bottom:10px ;
}


.vendor-logo-img {
  height: 51px;
  margin-left: -10px;

}

.vendor_link {
  display: inline-block;
  padding: 7px 12px;
  font-size: 12px;
  margin-bottom: 5px;
  transition: all 0.5s;
  margin: 0 6px;
  border-radius: 5px;
  width: 54px;
  height: 35px;
  margin-top: 0.7rem;
  text-decoration: none !important;
}

.vendor_link.vendor_inactiveLink {
  margin: 0.7rem 6px 0;
  width: 100%;
  transition: 0.4s;
}
.vendor_icon {
  display: flex;
  align-items: center;
}

.vendor_icon,
.vendor_link_text {
  font-size: 16px;
  height: 100%;
  font-weight: 600;
}

@media (max-width: 512px) {
  .sidebar {
    width: 100%;
  }
  .vendor_link {
    justify-content: center;
  }
}

.vendor_sidebar_outer_div {
  overflow: auto;
  z-index: 999999;
  position: relative;
}

.vendor_sidebarContaine-outer-div {
  position: fixed;
  max-height: 100%;
  transition: 0.4s;
  min-height: 100%;
  background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, var(--vendor-sidebar-outer-div) 88.94%);
}

.vendor_sidebarTop_heading {
  background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, var(--vendor-sidebar-outer-div) 88.94%);
  height: auto;
}

.vendor_menuBarOuterDiv {
  overflow: auto;
  max-height: calc(95vh - 50px);
  min-height: calc(95vh - 50px);
  margin-bottom: 5px;
  text-align: center;
}

.vendor_link_text {
  white-space: nowrap;
}


.vendor_menuBarOuterDiv::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.vendor_menuBarOuterDiv::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray-color);

  border-radius: 1em;
}

.vendor_menuBarOuterDiv::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-gray-color);

}

.vendor_menuBarOuterDiv::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color:var(--white-color);
}

.mainDiv {
  position: relative;
  transition: 0.4s;

}

.vendor_link_text {
  color: var(--white-text-color);
  margin: -5px 3px 0px 0px;
}

.vendor_sidebar_extraDiv {
  height: 0.08vw;
  background-color: var(--white-color);
}
.sidebar {
  width: 20%;
}

.vendor_sidebar_outer_container {
  width: 100%;
}

.vendor_link.vendor_inactiveLink .vendor_icon i {
  padding-right: 10px;
  margin: 0px 0px 0px 0px;
}

.vendor_link .vendor_icon i {
  display: inline-block;
  margin: -5px auto;
}

.vendor_link .vendor_icon i svg {
  margin-top: -10px;
}

.vendor_icon.activeIcon {
  filter: brightness(0) invert(1);
}

.vendor_link.vendor_sidebar-active-link,
.vendor_link:hover {
  background: var(--vendor-sidebar-link-active);
  border-radius: 5px;
  color:var(--white-text-color);
}
.venodr-sidebar-fa-fa-icon {
  font-size: 22px !important;
}

.vendor_sidebar-deactive-link {
  color:var(--white-text-color);
}

.vendor_link:hover {
  text-decoration: none;
}
.mainDiv {
  height: auto;
}

.vendor_sidebar_outer_container {
  background-color: var(--vendor-outer-container);

}

.vendor_sidebar_outer_container {
  background-color:var(--vendor-outer-container);

}

#menuBarIcon {
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 21px;
  height: 3px;
  background-color:var(--white-color);
  margin: 6px 0;
  transition: 0.4s;
  cursor: pointer;
}

.open_close .bar1 {
  transform: translate(0, 7px) rotate(-45deg);
}

.open_close .bar2 {
  opacity: 0;
}

.open_close .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}