
.ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
    height: 100% !important;
}
.ant-drawer-left .ant-drawer-content-wrapper {
    width: 300px !important;
    height: 100% !important;
}
.menu-sidebar-header-section {
    background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, var(--crrote-color) 88.94%);
    border-bottom: 1px solid #80808040;
    color: var(--white-text-color);
}
.menu-sidebar-header-content{
    display: flex;
    justify-content: space-between;
}
.menu-sidebar-header-content{
    display: flex;
    padding: 10px 10px;
    align-items: center;
    text-align: center;
}
.sidebar-username{
    font-size: 18px;
    display: flex;
    align-items: center;
    background: #0000;
    font-family: Arial;
    font-weight: 600;
    text-transform: capitalize;
}
.sidebar-log-out-div{
    cursor: pointer;
    font-size: 16px;
    font-weight: 700 !important;
    justify-content: flex-end;
}
.sidebar-log-out-icon{
    cursor: pointer;
    display: flex;
    font-size: 25px;
    font-weight: 700 !important;
    justify-content: flex-end;
    padding-right: 8px;
}
.menu-sidebar-close {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
    font-weight: 700 !important;
    cursor: pointer;
    padding-right: 10px;
}
.mobile-menu-trigger-category{
    display: none;
}
.menu-sidebar-header-section .bi-arrow-left{
    font-weight: 700 !important;
    font-size: 20px;
    margin-left: 7px;
}
.menu-sidebar-username {
    font-family: "Arial";
    font-weight: 600;
    background: transparent;
    text-align: left;
    line-height: 15px;
    padding-bottom: 10px;
    padding-left: 10px;
    text-transform: capitalize;
}
.menuBar-sidebar .ant-drawer-body {
    overflow: hidden;
    background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, var(--crrote-color) 88.94%);
    padding: 0px;
}
.menu-sidebar-body {
    margin: 0;
    padding: 0;
    height: 80vh !important;
    overflow: auto;
}
.menu-sidebar-outer-div {
    padding: 5px 15px 5px 15px;
    margin: 5px;
    padding: 10px;
    color: var(--white-text-color);
    cursor: pointer;
}
.menu-sidebar-outer-div:hover {
    background: rgba(256, 256, 256, 0.6);
    border-radius: 5px;
    color: var(--white-text-color);;
}
.menubar-sidebar-inner-div {
    color: var(--white-text-color);
    margin-top: -1px;
    font-size: 18px !important;
}
.menubar-sidebar-icon {
    margin-right: 10px;
}
.menu-sidebar-log-outer-outer-div {
    bottom: 0px !important;
    padding: 5px 15px 5px 15px;
    padding: 10px;
    margin: 5px;
    color: var(--white-text-color);
    cursor: pointer !important;
}
.menu-sidebar-log-outer-outer-div:hover {
    background: rgba(256, 256, 256, 0.6);
    border-radius: 5px;
    color: var(--white-text-color);;
}
.menubar-sidebar-link {
    border: none !important;
    text-decoration: none !important;
}
.menu-sidebar-body::-webkit-scrollbar,
.review::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.menu-sidebar-body::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
    background-color: var(--sura-text-color);
    border-radius: 1em;
}
.menu-sidebar-body::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
    background-color: var(--sura-text-color);
}
.menu-sidebar-body::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
    background-color: var(--scrollbar-color);
}
.isHoverSection {
    color: var(--white-text-color);
}
.menubar-sidebar-text{
    color: var(--white-text-color);
}
.menu-sidebar-outer-div:hover .menubar-sidebar-icon,
.menu-sidebar-outer-div:hover .menubar-sidebar-text {
    color: var(--white-text-color);
}
.menu-sidebar-log-outer-outer-div:hover .menubar-sidebar-icon,
.menu-sidebar-log-outer-outer-div:hover .menubar-sidebar-text {
    color: var(--white-text-color);
}
.menubar-logout-text{
    font-size: 12px;
}
@media (max-width:1125px) {
    .vendor_Header_UserName {
        display: none;
    }
    .menu-sidebar-temp {
        display: none;
    }
}
.ipad-localstorage-profile {
    display: flex;
    justify-content: center;
}
.isActiveSection {
    color: var(--sura-text-color);
    background: #ddd;
}
.near-me-section {
    display: none !important;
}
@media (max-width:768px) {
    .near-me-section {
        display: block;
    }
}
.menubar-sidebar-inner-div{
    white-space: nowrap;
}
@media (max-width:991px) {
.mobile-menu-trigger-category{
    display: block;
}
}