a {
    outline: none;
}

.button:before {
    background-color:var(--white-color);
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
}
.button:hover:before {
    top: 0;
}
.button:hover {
    color:var(--button-hour-blue);
    transition: 0.25s;
}
.button:after {
    position: absolute;
    right: 2.34375rem;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(50%);
    -ms-transform: translateY(-50%) translateX(50%);
    transform: translateY(-50%) translateX(50%);
    font-size: 1.75em;
}
.accountDetails{
    margin-left:8px !important;
}

.AccountSettingHeader-container {
    padding: 5px;
}


@media (max-width:468px) {
    .account-details-tab .user-bookings-tab{
       font-size: 11px !important;
    }
}
@media (max-width:380px) {
    .account-details-tab .tab-2{
        margin-top: 10px;
    }
}