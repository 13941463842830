  .form-select:focus{
    box-shadow: none;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
  }
  .ant-select-dropdown{
    z-index: 1056;
  }
  .ant-picker-outlined {
    border-radius: 6px;
  }
#newBranchModal .modal-dialog .modal-content{
  margin-left: 5%;
  width: 90%;
}
.new-branch-icon {
  position: absolute;
  top: 17%;
  right: 16%;
  transform: translate(50%, -50%);
  background-color: white;
  border: 2px solid #D9D9D9;
  border-radius: 25%;
  padding: 3px;
}

.form-cancel-branch,.form-cancel-branch:hover{
  margin-right: 10px;
    background:var(--white-color);
    color: var(--black-color);
    border: 1px solid var(--finance-input-border);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 41px;
    width: 120px;
}

.form-row-branch>.form-group>.avatar-edit{
  position: absolute;
}

.form-row-branch>.form-group>.sc-aXZVg{
  width: 35px;
  height: 43px;
  border-radius: 50%;
  overflow: hidden;
}