.aboutus-img-div {
  object-fit: cover;
}

.aboutus-img-div img {
  width: 100%;
  height: 389px;
  object-fit: cover;
  object-position: 50% 50%;
}

.title-business {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  margin: auto;
}

.aboutus-title {
  position: absolute;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 3vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white-text-color);
  transform: translate(-12px, 181px);
}

.sub-heading-content {
  font-weight: 550;
  font-weight: bold;
}

@media screen and (max-width:450px) {
  .aboutus-title,
  .title-business {
    font-size: 20px;
  }
}

.about-img-div img,
.about-sub-section img {
  width: 350px;
  height: 300px;
  background-size: cover;
  object-fit: cover;
  object-position: 50% 50%;
}

.about-image-desc {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@media (min-width:1125px) {
  .about-us-outer-container {
    margin-top: 150px !important;
  }
}

@media (max-width:1124px) and (min-width:992px) {
  .about-us-outer-container {
    margin-top: 160px;
  }
}

@media (max-width:991px) and (min-width:738px) {
  .about-us-outer-container {
    margin-top: 110px;
  }
}

@media (max-width:767px) and (min-width:421px) {
  .about-us-outer-container {
    margin-top: 80px;
  }
}
@media (max-width:421px) {
  .about-us-outer-container {
    margin-top: 130px;
  }
}


@media (max-width:575px) {
  .aboutus-img-div img {
    height: auto !important;
  }

  .aboutus-title {
    position: absolute;
    display: flex;
    justify-content: unsafe;
    font-size: 3vw;
    font-weight: 500;
    line-height: revert-layer;
    align-items: center;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-text-color);
    transform: translate(0%, 417%) !important;
  }
}

.about-us-outer-container>.container>.row>.null>.description_in_thai>pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: .875em;
  white-space: pre-wrap;
  word-wrap: break-word;
}