
.outer-footer-inner-container {
  background:  var(--service-bg-color);
  overflow: auto;
}

.outer-footer-head-act-div {
  padding: 0px 20px 0px 20px;
}

.outer-footer-heading {
  color: white;
  font-weight: bold;
}

.outer-footer-sub-heading {
  color: white;

}
input{
  font-family: Arial;
}
.outerfooter-error{
  display: flex;
  justify-content: left;
  color: white;
  font-size: 11px;
}

.outer-footer-subscribe-btn {
  background: rgba(255, 255, 255, 1) !important;
  color: rgba(209, 39, 113, 1) !important;
}


.outer-footer-action-outer-div>div>.signup {
  /* width: 751px;
  height: 50px; */
  width: 673px;
  height: 42px;
  background-color: white;
  border: 4px solid white;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  margin: auto;
}

.outer-footer-email-field {
  background-color: black;
  padding: 0px 5px 0px 10px;
  caret-color: #fc4e03;
  background-color: white;
  font-size: 20px;
  color: #383838;
  outline-style: none;
  border: none;
  border-radius: 50px 0px 0px 50px;
  font-family: 'Arial';
}
.outer-footer-email-field::placeholder{
  font-family: 'Arial';
  font-size: 20px;
}

.outer-footer-form-submit-btn {
  margin: -2px;
  height: 38px;
  width: 207px;
  background:  var(--category-hover-color);
  border: none;
  font-size: 20px;
  float: right;
  color: white;
  border-radius: 6px;
  font-family: 'Arial';
  text-transform: uppercase;
}

.outer-footer-form-submit-btn:hover {
  background:  var(--category-hover-color);
}




h1 {
  font-size: 30px
}

.outerfooter-mail {
  color: white;
  font-size: 40px;
  display: flex;
}

@media (max-width:512px) {
  .outer-footer-action-outer-div>div>.signup {
    width: 350px;
  }

  .outer-footer-action-outer-div>div>.signup>form {
    display: flex;
  }

  .outer-footer-action-outer-div>div>.signup>form {
    display: flex;
  }

  .outer-footer-email-field {
    width: 70%;
  }


  .outer-footer-form-submit-btn {
    width: 108px;
    font-size: 16px;
  }
}