td ,th{
    border-width: 1px;
}
.web-view-filter-icon{
    background-color: var(--icon-color);
}
.delete-container p{
    color: var(--input-field-color);
}
.delete-pop-up-message{
    color: var(--input-field-color);
}
.tdclass-booking-user-number .hl05eU{
justify-content: right;
}
.blure-bacground {
    opacity: 0.5;
}

.bookinig-cancelled {
    color: var(--red-color) !important;
    background: var(--red-color);
}
.pay-again-td{
    vertical-align:middle;
}
.pay-again-btn{
width: 100px;
border: 1px solid lightgreen;
background: white;
padding: 2.5px 8px;
border-radius: 6px;
letter-spacing: 0.4px;
text-transform: capitalize;
}
.payment-success{
    background: rgb(212, 255, 212);
  color: green;
  padding: 3px 8px;
  border-radius: 6px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}
.payment-failed{
    background: #ffd4d4;
color: #FF0000;
padding: 3px 8px;
border-radius: 6px;
letter-spacing: 0.4px;
text-transform: capitalize;
}
.success-btn{
    display: flex;
    justify-content: center;
}

.bookinig-confirm {
    color: var(--green-color) !important;
}

.bookinig-complete {
    color: var(--blue-color) !important;
}

.bookinig-expire {
    color: var(--orange-color) !important;
}

.booking-notAvailable {
    padding: 250px;
}


.action_edit {
    color: var(--input-field-color);
}


.filterSvg {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 550;
    white-space:nowrap;
}

.clear-filter {
    width: 100% !important;
    height: 35px !important;
    font-size: 11px !important;
    background: var(--search-filter-input-feild-bg) !important;
    color: var(--black-color)!important;
    box-shadow: 0 2px 5px var(--black-color);
}
.option li {
    display: flex;
    cursor: pointer;
    align-items: center;
}


.user-filter-input:focus {
    outline: none;
    border-color: var(--search-btn);
}

.user-filter-input::placeholder {
    font-size: 12px;
}

.user-filter-select>.ant-select-arrow>.anticon-down svg{
    font-size: 12px ;
     
}
.user-filter-select>.ant-select-selector{
    background-color: var(--search-filter-input-feild-bg) !important;
}

.user-filter-input .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 18px;
    font-size: 15px !important;
}

.editiconsize-user-booking {
    height: 18px;
}

.dull-image {
    display: none;
    opacity: 0.5;
    pointer-events: none;
}

.user-booking-filter-input-feild-date {
    background-color: var(--search-filter-input-feild-bg);
    height: 2.9em;
    padding: 0 5px;
    border: 1px solid var(--search-btn);
    border-radius: 5px;
    width: 100%;
    max-width: 8vw;
    margin-top: 2px;
    font-size: 12px;
}

.user-booking-filter-input-feild-date:focus {
    outline: none;
    border-color: var(--search-btn);
}

.tdclass-booking-user {
    font-size: 14px;
    font-weight: 300;
    white-space: nowrap;
}

.thclass-booking-user {
    white-space: noWrap;
    font-size: 15px;
    font-weight: 600;
}

.thclass-booking-user > .ant-select>.ant-select-selector{
    border-radius: 5px !important;
}

.user-bookings-tab {
    font-size: 14px;
    padding-bottom: 4px;
}

.user-bookings-tab:hover {
    cursor: pointer;
}

.user-bookings-tab-active {
    border-bottom: 3px solid var(--sura-text-color);
}

.user-booking-tab-outer-div {
    padding: 7px 0;
    font-weight: 600;
}

@media only screen and (max-width: 820px) {
    .editiconsize-user-booking {
        height: 14px;
    }
    .tdclass-booking {
        width: 30vw;
        font-size: 14px;
        font-weight: 300;
    }

    .thclass-booking {
        width: 30vw;
        font-size: 15px;
        font-weight: 600;
    }

    .user-booking-filter-input-feild-date {
        height: 2.9em;
        padding: 0 5px;
        border: 1px solid var(--search-btn);
        border-radius: 5px;
        width: 100%;
        max-width: 11vw;
        margin-top: 2px;
        font-size: 12px;
    }
    .clear-filter {
        width: 80px !important;
        height: 35px !important;
        font-size: 11px !important;
        background: var(--search-filter-input-feild-bg) !important;
        color: var(--black-color) !important;
        box-shadow: var(--circle-box-shadow);
    }
    .user-filter-select {
        width: 11vw !important;
    }
}

@media only screen and (max-width: 600px) {

    .tdclass-booking {
        width: 30vw;
        font-size: 14px;
        font-weight: 300;
    }

    .thclass-booking {
        width: 30vw;
        font-size: 15px;
        font-weight: 600;
    }


    .user-booking-filter-input-feild-date {
        height: 2.9em;
        padding: 0 5px;
        border: 1px solid var(--search-btn);
        border-radius: 5px;
        width: 100%;
        max-width: 20vw;
        margin-top: 2px;
        font-size: 12px;
    }

    .user-filter-select {
        width: 20vw !important;
    }

    .editiconsize-user-booking {
        height: 14px;
    }

}

@media only screen and (max-width: 375px) {

    .user-filter-select {
        width: 29vw !important;
    }
   .mobile-view-filter-icon-outer-div>.user-booking-tab-outer-div>.user-bookings-tab{
      font-size: 12px;
   }
    
}
.ant-pagination .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: var(--black-color);
    background: var(--search-btn);
    border-radius: 5px;
}

.ant-pagination .ant-pagination-item-active a {
    color: var(--white-text-color);
    background: var(--sura-text-color);
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: none;
    transition: all 0.2s;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: var(--search-btn);
    border: 1px solid transparent;
    border-radius: 6px;
    outline: none;
    transition: all 0.2s;
    border-color: none;
}

.ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: var(--team-card-bg-color);
    border-color: var(--sura-text-color);
}

.user-filter-select {
    margin-top: 2px;
    height: 2.5em!important;
    max-width: 28vw !important;
    width: 100%;
    border-radius: 8px;
}

.scroller-container {
    overflow-x: hidden;
    overflow-y: auto;
    text-transform: capitalize;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
    border: 1px solid var(--search-btn);
    margin: 0 2px;
    cursor: pointer;
}


.ant-pagination-item-active {
    background-color: var(--ant-pagination-item-active-bg);
    border-color: var(--ant-pagination-item-active-bg);
    color:var(--team-card-bg-color);
}

.table>thead {
    vertical-align: top !important;
}

.user-filter-input::-webkit-inner-spin-button,
.user-filter-input::-webkit-outer-spin-button,
.user-filter-input::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

.ant-picker-outlined {
    border-color: var(--search-btn) !important;
}

.search-filter-icon {
    margin: 5px !important;
    text-align: center;
}

.user-booki-list-fix-content-row{
    padding: 0px 10px 0px 0px;
}

.btn-status{
  padding: 3px 8px;
  border-radius: 6px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.btn-status.confirmed{
  background :var(--btn-status-bg);
  color :var(--colors-warning, #F29339);
}
.btn-status.initial{
    background : #dddbd9;
    color :var(--colors-warning, #858382);
  }

.btn-status.cancelled_by_customer{
/*  border: 1px solid red;*/
  color :var(--cancelled);
  background :var(--btn-status-bg);

}

.btn-status.cancelled_by_provider{
/*    border: 1px solid red;*/
    color :var(--cancelled);
    background :var(--btn-status-bg);
}

.btn-status.completed{
/*  border: 1px solid blue;*/
  background :var(--btn-status-bg);
  color :var(--completed);
}

.btn-status.expired{
/*  border: 1px solid #FFEA00 ;*/
  background :var(--expired-bg);
  color :var(--expired);

}

.thclass-booking-user-number{
    white-space: noWrap;
    font-size: 15px;
}

.tdclass-booking-user-number{
    text-align: right;
    white-space: nowrap;
}

.ant-modal-root .ant-modal-mask {
    position: fixed;
    inset: 0;
    z-index: 1000;
    height: 100%;
    background: 0;
    pointer-events: none;
}

.ant-modal-content,.modal-content{
    border: none;
    box-shadow: var(--ant-modal-content-box-shadow1) 0px 10px 20px, var(--ant-modal-content-box-shadow2) 0px 6px 6px !important;
}

.user-booking-list-shop-name{
    cursor: pointer;
}
.user-booking-list-shop-name:hover{
    color: var(--card-btn-color);
}

.mobile-view-filter-icon{
    display: none;
}

@media (max-width:575px) {
    .mobile-view-filter-icon{
        display: block;
    }
    .web-view-filter-icon{
        display: none;
    }
    .mobile-view-filter-icon-outer-div{
        display: flex;
        justify-content: space-between;
    }
    .outer-search-feild-booking-buisiness>.me-2{
        margin-right: 0rem !important;
    }
    .outer-search-feild-booking-buisiness>.outer-search-feild-booking-buisiness-inner{
        width: 100%;
    }
    .user-booki-list-fix-content-row {
        padding: 0px 0px 0px 0px;
    }
    
}

.create-obj-th>.ant-picker{
    min-width: 8vw;
}
.user-filter-input{
    background-color: #F8F8F8;
    height: 2.9em;
    padding: 0 5px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
}

