.shop-modal-rating-div {
    padding: 10px 10px;
    text-align: left;
    border: 1px solid rgba(217, 217, 217, 1) 
}
.shop-modal-rating-div .ant-rate-star:not(:last-child){
    margin-inline-end: 5px!important;
}
.shop-modal-star1,.shop-modal-star1 span{
    display: flex;
    justify-content: space-evenly;
    font-size: 25px;
    color: var(--star-color)!important;
}

.icon-color-grey{
    color: grey;
}
.shop-modal-button Button,.shop-modal-button Button:hover {
    width: 100px;
    background: var(--bg-color)!important;
    color: white!important;
    font-weight: bold;
}

.shop-modal-button {
    justify-content: center;
    padding: 10px;
}

.shop-modal-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
}

.shop-modal-review {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1.399999976158142px;
}

.shop-modal-rating {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.shop-modal-header {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;

}
.shop-modal-textarea-div{
    margin-left: -10px;
}
.shop-modal-textarea{
    width: 103%;
}