.form-control:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color:var(--white-color);
  color:var(--input-field-color)!important;
}

.vendor_header_outerDiv {
  padding: 0px 10px 0px 16px;
}


.localstorage-profile {
  vertical-align: middle;
  width: 35px;
  height: 30px;
  border-radius: 50%;
}

@media (max-width:1125px) {
  .user-functuanilty {
    display: none;
  }
}