.service-section-service-details {
    position: relative;
}

.service-section-service-img-div img {
    height: 250px;
    width: 100%;
}

.service-section-heart-icon {
    position: absolute;
    top: 5px;
    right: 35px;
    width: 44px;
    height: 44px;
    padding: 8px;
    background: white;
    border-radius: 50%;
    display: flex;
    gap: 15px;
}

.service-section-heart-icon i {
    font-size: 20px;
}

.service-section-content-section {
    height: 100%;
    /* Ensures it takes the full height of the column */
}

.service-section-content-section {
    position: relative;
}

.service-section-action {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.service-section-action .card-main__footer {
    display: flex;
    gap: 15px;
    align-items: center;
}

.service-section-action .bi-cart-plus-fill {
    font-size: 21px;
}

.service-section-content-section .service-name {
    color: var(--sura-text-color);
}

.service-opening-hours {
    border-left: 1px solid grey;
    padding: 15px;
}

.service-section-content-section .organization-name:hover {
    color: var(--sura-text-color);
    cursor: pointer;
}

.deal-of-the-day-service-heading:hover {
    color: var(--sura-text-color);
    cursor: pointer;
}

.service-section-desc-section {
    background: var(--white-btn-bg-color);
    padding: 13px 15px;
    outline: 1px solid gainsboro;
    border-radius: 5px;
}

.deal-of-the-day-content-section>.price-section>.hl05eU>.yRaY8j {
    margin-top: 0px !important;
}

.service-section-related-services .infinite-scroll-component {
    overflow: hidden !important;
  }