* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
.side-small-img {
  height: 65%;
  overflow-y: auto;
  overflow-x: hidden;
}
.search-display-desc-outer-div{
  /* margin-left: -15px; */
}
.side-small-img::-webkit-scrollbar {
  display: none;
}
.column {
  float: left;
  padding: 0px 0px 10px 0px;
}
.width100{
  width: 100%;
}
.column img {
  cursor: pointer;
  width: 100px;
  height: 81px;
}
.column img:hover {
  opacity: 1;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.container {
  position: relative;
}
#expandedImg {
  /* height: 52vh; */
  width: 100%;
  /* object-fit: cover; */
  aspect-ratio: 4/3;
}
.image-gallery-single-img{
  height: 443px;
  aspect-ratio: 4/3;
  width: 100%;
}
@media (max-width:991px) {
  .column img {
    opacity: 1;
  }
}
@media screen and (max-width:768px) {
  .side-small-img {
    height: 255px;
    overflow: auto;
  }
}
@media screen and (width:768px){
  .map-div{
    height: 40vh;
  }
  .side-small-img {
    height: 406px;
    overflow: auto;
  }
}
@media screen and (max-width:400px) {
  .side-small-img {
    height: 44vh;
  }
  .column img {
    /* height: auto; */
    padding: 5px;
  }
}
.single-shop-img {
  padding: 0px 0px 0px 2px;
}
.search-display-desc-outer-div-mobile {
  display: none;
}
@media (max-width:574px) {
  .search-display-desc-outer-div {
    display: none;
  }
  .search-display-desc-outer-div-mobile {
    display: block;
  }

  .side-small-img {
    height: auto;
    overflow: auto;
    display: flex !important;
    gap:5px;
    /* justify-content: space-between !important; */
  }
  #expandedImg {
    height: auto;
  }
}
@media (max-width: 581px) {
  .search-display-desc-outer-div .slick-prev,.search-display-desc-outer-div .slick-next {
      width: 16px !important;
      right: 10px !important;
  }
}