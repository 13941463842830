.hl05eU>.yRaY8j{
    font-weight: 400;
    font-size: 14px;
    margin-left: 5px;
    text-decoration: line-through;
}
.currency-display-sub-price-second{
    font-weight: 400;
    font-size: 10px;
    margin-top: 4px;
    margin-left: 5px;
    text-decoration: line-through;
}