.headin-oute-container {
    margin-top: 80px;
}
.heading-user-profile-outer-div {
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.heading-inner-box{
    padding: 10px;
    border-radius: 0px;
    background-color:var(--white-btn-bg-color);
    border: 1px solid var(--step-icon-bg);
    padding: 0.5rem 1rem 0.5rem 1rem;
}
.heading-icon{
    border: 1px solid var(--heasing-icon-shadow);
    padding: 5px 7px;
    border-radius: 6px;
    margin-right: 6px;
    font-weight: bold;
}