.team-card .card {
    position: relative;
    margin-bottom: 24px;
    background-color: var(--team-card-bg-color);
    border-radius: 3px;
    -webkit-box-shadow: var(--team-card-box-shadow);
    box-shadow: var(--team-card-box-shadow);
}

.team-card .doctor-profile {
    text-align: center;
}

.vendor-team-list-modal>label {
    position: relative;
    display: flex;
    align-items: center;
    border: dashed 2px #0658c2;
    padding: 8px 16px 8px 8px;
    cursor: pointer;
    flex-grow: 0;
    height: 135px !important;
    min-width: 135px !important;
    max-width: 135px !important;
    border-radius: 50%;
    opacity: 0;
}

.team-card .profile-header {
    max-height: 150px;
    color:var(--white-text-color);
}

.profile-userbuttons {
    display: flex;
    justify-content: space-between;
}

.team-card .user-name {
    padding: 3px;
    font-size: 22px;
    text-align: center;
    padding-top: 10px;
}

.team-card .user-img-team {
    padding: 3px;
    border-radius: 50% 50% 50% 50%;
    max-width: 112px;
    margin-top: -70px;
    box-shadow:var( --team-card-img-box-shadow);
    margin-bottom: 20px;
    width: 112px;
    object-fit: fill;
    height: 112px;
    object-position: center;
}

.team-card a {
    text-shadow: none;
    color:var(--team-card-ancor);
    text-decoration: none;
}

.team-card .profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}

.team-card .btn.btn-sm {
    font-size: 11px;
    padding: 6px 18px;
}

.team-card .profile-header {
    min-height: 150px;
    color: var(--white-text-color);
}

.team-card .bg-b-purple {
    background: var(--team-card-bg-b-purple);
}

.team-card .doctor-profile {
    float: left;
    width: 100%;
    text-align: center;
}

.vendor-team-list-add-modal {

    top: 20;
}

.vendor-team-list-add-modal div .ant-modal-content .ant-modal-footer {
    display: none !important;
}

.vendor-team-list-add-modal .ant-modal-content .ant-modal-footer {
    display: none !important;
}

.ant-modal-close {
    display: none;
}

.vendor-team-list-modal .avatar {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.disable {
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
}

.vendor-team-list-modal>img {
    position: absolute;
}

.vendor-team-list-modal>.avatar-edit {
    position: absolute;
}

.vendor-team-list-modal {
    position: relative;
}

.vendor-team-list-modal>.sc-aXZVg {
    height: 39px;
    width: 36px;
    overflow: hidden;
    border-radius: 50%;
}

.vendor-team-list-outer-div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.vendor-team-list-outer-div{
    background: #fff;
    border-radius: 5px;
    outline: 1px solid gainsboro;
}

.vendor-team-list-outer-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 10px 5px 10px;
  }
  
  .team-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .profile-userbuttons {
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
    padding-top: 5px;
    border-top: 1px solid #e0e0e0;
    position: relative;
  }
  
  .vendor-team-list-outer-div .profile-userbuttons {
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
  }
  
  .profile-userbuttons .btn {
    padding: 0px 10px;
    margin: 0 5px;
    border: none;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
  }
  
  .profile-userbuttons .btn:hover {
    color: #0056b3;
  }
  
  .cursor-point-class {
    cursor: pointer;
  }
  .team-member-disable-email{
    background-color: var(--bs-secondary-bg);
    opacity: 1;
}