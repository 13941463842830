/* Styles for the select dropdown */
.countryCode-class {

    background-color: #f9f9f9; /* Background color */
    appearance: none; /* Remove default arrow in modern browsers */
    outline: none; /* Remove the blue outline when focused */
  }
  
  /* Add an arrow icon to the select */

  
  /* Style the options */
  .countryCode-class option {
    padding: 10px;
  }
  