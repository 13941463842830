.faq-img-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    position: relative;
    top:130px;
    font-weight: bold;
    height: auto;
    width: 100%;
    color:var(--white-text-color);
}

.faq_container {
  min-height: 57vh;
  box-sizing: border-box;
}

.loader-faq-section {
  margin-top: 420px;
}

.faq_img_container {
  margin-left: 30px;
  margin-right: 30px
}

.faq-item {
  white-space: nowrap;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: var(--offer-header-bg-color);
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.faq-item.active {
  background-color: var(--category-hover-color);
  font-weight: bold;
  color: #ffff;
}

@media screen and (max-width:400px) {
  .faq-img-div {
    display: block;
  }

  .faq-item {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width:315px) {

  .faq-img-div {
    object-fit: cover;
    padding: 0;
  }

  .faq_img_container_card {
    margin-top: 10px;
    margin-bottom: 14px;
  }

  .faq_img_container {
    margin-left: 0px;
    margin-right: 0px;
  }

  .faq_container {
    overflow-x: hidden;
  }

}

@media (min-width:1125px) {
  .faq_container {
    margin-top: 130px;
  }
}

@media (max-width:1124px) and (min-width:768px) {
  .faq_container {
    margin-top: 160px;

  }
}

@media (max-width:767px) {
  .faq_container {
    margin-top: 120px;
  }
}