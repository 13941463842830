
.faq-setting-answer-thai pre{
    word-wrap: break-word;
    word-break: break-all;
    overflow-x: hidden;
}

.faq_hr {
  width: 95%;
}
.faq-setting-answer {
  padding-right: 3rem;
  padding-left: 1rem;
}

.faq-setting-answer-thai {
  padding-right: 3rem;
  padding-left: 1rem;
}

@media screen and (max-width:680px) {
  .faq-setting-answer {
    padding-right: 1rem;
  }

}

@media screen and (max-width:400px) {

  .faq-setting-answer {
    padding-right: 1rem;
    font-size: 14px;
    padding-left: 1rem;
  }

  .faq-setting-contain {
    margin-left: 4px;
  }

}

@media (max-width:767px)and (min-width:421px){
  .faq-img-div {
    position: relative;
    top: 55px;
  }
    .faq_container {
      margin-top: 56px;
  
  }
}
@media (max-width:991px)and (min-width:767px){
  .faq-img-div {
    position: relative;
    top: 76px;
  }
    .faq_container {
      margin-top: 80px!important;
  
  }
}