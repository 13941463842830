@import "../../../assets/styles/style.css";

:root {
  --font-base: "Lato";
  --fs-700: 2.986rem;
  --fs-600: 2.488rem;
  --fs-500: 2.074rem;
  --fs-400: 1.728rem;
  --fs-300: 1.44rem;
  --fs-200: 1.2rem;
  --fs-100: 1rem;
  --fs-50: 0.833rem;
  --white: #ffffff;
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b <div className="modal-footer" > <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button> <button type="button" className="btn btn-primary" >Save changes</button> </div>;
  --grey-900: #0f172a;
  --grey-950: #020617;
  --primary: #006ed8;
  --primary-50: hsl(209, 100%, 52%);
  --text: var(--grey-500);
  --text-alt: var(--grey-900);
  --background: var(--grey-200);
  --background-alt: var(--grey-100);
  --background-shade: var(--grey-100);
}
@media (prefers-color-scheme: dark) {
  :root {
    --text: var(--grey-500);
    --text-alt: var(--grey-100);
    --background: var(--grey-900);
    --background-alt: var(--grey-800);
    --background-shade: var(--grey-700);
  }
}
h1,
h2,
h3,
h5,
h6 {
  line-height: 1.15;
  font-weight: 700;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.edit-team-person-header {
  border-bottom: 1px solid var(--warning-container-border);
}
.title {
  margin-top: unset;
  margin-bottom: 1rem;
  text-align: center;
}
.form-row {
  margin-bottom: -8px;
}
.form-group {
  flex-grow: 1;
  flex-shrink: 1;
}
.select-margin {
  font-size: 15px;
  width: 100%;
  height: 2.7em;
  border-radius: 0px;
  appearance: auto;
}
.select-margin .ant-select-selector {
  border-radius: 6px !important;
}
.input-custom-size {
  padding: 18px 5px;
}
.input-custom-size-desig {
  margin: 3px 0px 0px 0px;
  padding: 18px 5px;
}
.form-input-team-member {
  padding: 18px 5px;
  color: var(--text-alt);
  border:1px solid #d9d9d9;
  /* box-shadow: 0 0 1px var(--text); */
  width: 100%;
  margin-top: 5px;
  height: 2.6em;
  border-radius: 6px;
}
.form-submit-team-member {
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  background: var(--bg-color);
  color: var(--white);
  height: 41px;
  width: 120px;
  padding-top: 0px;

}

.form-cancel-team-member {
  margin-right: 10px;
  background: var(--white-btn-bg-color);
  color: black;
  border: 1px solid var(--warning-container-border);
  border-radius: 0.25rem;
  cursor: pointer;
  height: 41px;
  width: 120px;
  padding-top: 0px;
}
.form-submit:hover {
  background-color: var(--primary-50);
}
.custom-estric {
  margin-left: 2px;
  color:var(--red-color)!important;
}

.ant-modal-content {
  width: 85%;
  margin-left: 3em;
}
.ant-modal-body .team-member-modal{
  max-height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3px;
}
.ant-modal-body .team-member-modal .select-margin{
  padding: 2px;
  font-size: 15px;
  width: 100%;
  height: 2.8em;
  border-radius: 6px;
  appearance: auto;
}
/* -------------------------------------------.ant-modal-body .team-member-modal scroll start------------------- */
.ant-modal-body .team-member-modal::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.ant-modal-body .team-member-modal::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color) !important;
  border-radius: 1em !important;
}
.ant-modal-body .team-member-modal::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color) !important;
}
.ant-modal-body .team-member-modal::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color:var(--warning-container-bg-color) !important;
}
/* -------------------------------------------.ant-modal-body .team-member-modal scroll end------------------- */
@media (max-width:400px){
  .workingHourseDayDiv{
    width: 100%;
    /* height: 300px; */
    overflow-x: auto;
  }
  .workingHourseDayDiv .row{
     width: 300px;
  }
}
@media screen and (min-width: 40rem) {
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  .title {
    margin-top: unset;
    margin-bottom: 2rem;
    text-align: center;
  }
  .form-wrapper {
    max-width: 50ch;
  }
  .form-row {
    display: flex;
  }
  .form-row--inline {
    display: inline-flex;
  }
  .form-row>.form-group {
    margin-right: .5rem;
    margin-left: 0.5rem;
  }
  .form-row>.form-group:first-child {
    margin-left: unset;
  }
  .form-row>.form-group:last-child {
    margin-right: unset;
  }
}
#lang {
  width: 470px;
  border-color: var(--lang-border-color);
  border-radius: 10px;
}
.btn-close {
  margin-left: 350px;
  margin-top: -20px;
}
.edit-profile {
  height: 65px;
  display: flex;
  justify-content: center;
  position: relative;
}
.error{
  padding-bottom: 1px;
  margin-bottom: 1px;
  font-size: 12px;
  margin-top: 5px;
}
#phone-err {
  margin-bottom: 20px;
  color: var(--phone-err);
}
.edit-profile-plus-icon {
  cursor: pointer;
}
.edit-profile-plus-icon {
  cursor: pointer;
}
.circle-background {
  margin-left: 46px;
  margin-top: 55px;
  width: 20px;
  height: 20px;
  background-color:var(--white-text-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-input-phone {
  color: var(--text-alt);
  border:1px solid #d9d9d9;
  height: 2.3em;
  width: 100%;
  border-radius: 6px;
}

@media screen and (max-width:639px) {
  #email {
    width: 75vw;
  }
  #lang {
    width: 75vw;
    ;
  }
}
@media screen and (max-width:549px) {
  #lang {
    width: 78vw;
    ;
  }
}
@media screen and (max-width:380px) {
  .title {
    margin-top: unset;
    font-size: 16px;
    margin-bottom: 1rem;
    text-align: center;
  }
  .form-cancel-team-member {
    margin-right: 10px;
    background: var(--dashboard-filter-inner-div-bg);
    color: var(--black-color);
    border: 1px solid var(--warning-container-border);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 34px;
    width: 80px;
    padding-top: 0px;
  }

  .form-submit-team-member {
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    background: var(--bg-color);
    color: var(--white);
    height: 34px;
    width: 80px;
    padding-top: 0px;
  }
  .ant-modal-content {
    width: 100%;
    margin-left: 0em;
  }
}
@media screen and (max-width:282px) {
  #lang {
    width: 78vw;
    ;
  }

}
.form-input:focus {
  border-color: var(--warning-container-border) !important;
}

.form-input-phone::-webkit-inner-spin-button,
.form-input-phone::-webkit-outer-spin-button,
.form-input-phone::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.edit-team-member-checkbox input[type="checkbox"]:checked {
  background-color:var(--red-color) !important;
  border-color: #007bff;
}
.ant-picker-ok button {
  background: var(--sura-text-color);
  color: white;
  font-weight: bold;
}
.ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled,
.ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled+.ant-picker-time-panel-cell {
  display: none;
}
.ant-modal-footer button {
  font-weight: bold;
}
.ant-modal-footer .ant-btn-primary {
  background: var(--sura-text-color);
  color: white;
  font-weight: bold;
}
.modal-backdrop.fade {
  opacity: 1 !important;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}


.edit-team-member-checkbox input[type=checkbox] {
  appearance: none; /* Remove default checkbox styles */
  width: 16px;
  height: 16px;
  background-color: white; /* Default background */
  border: 1px solid #ccc; /* Default border */
  cursor: pointer;
  border-radius: .25em;
}

.edit-team-member-checkbox input[type=checkbox]:checked {
  background-color: var(--sura-text-color) !important;
  border-color: var(--sura-text-color) !important;
  color: white; /* Ensure checkmark color contrasts with background */
}

.edit-team-member-checkbox input[type=checkbox]:checked::before {
  content: '✔'; /* Checkmark */
  display: block;
  text-align: center;
  line-height: 16px; /* Same as checkbox height */
  font-size: 14px;
  color: white; /* Checkmark color */
}
