.upload-file-modal-div {
    position: relative;
    display: flex;
    width: 200px;
    height: 110px;
    margin: auto;
    border-radius: 10px;
    border: 2px dashed var(--input-field-color);
    align-items: center;
    justify-content: center;
}
.upload-file-modal-div-after-image{
    width: 100%;
    height: 200px;
    margin: auto;
    border-radius: 10px;
    border: 2px dashed black;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
}

.upload-file-img-icon {
    cursor: pointer;
}

.upload-file-fileInput {
    cursor: pointer;
}

.crop-image-file{
    cursor: pointer;
    opacity: 0;
    width: 280px;

}

.upload-file-h6-tag {
    padding: 0px 0px 10px 10px;
    font-size: 14px;
    font-weight: 800;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: center;
    color:var(--input-field-color);
}

.upload-modal-cancel-button {
    border: 1px solid var(--review-rating-active-btn) !important;
    /* width: 150%; */
}

.upload-modal-upload-button {
    background: var(--bg-color);
    color: var(--white-text-color) !important;
}

.upload-modal-cancel-button:hover ,.upload-modal-cancel-button{
    color: var(--input-field-color) !important;
}

.upload-file-button-outer-div {
    margin-top: -2vh;
    padding: 0px 80px 0px 80px;
}

.upload-file-cancel-button {
    float: left;
}

.upload-file-upload-button {
    float: right;
}

.upload-image-moda {
    margin-left: 3.2vw;
}

.upload-file-select-image {
    width: 100%;
    height: 200px;
    padding: 3px;
}

@media (max-width:575px) {
    .upload-file-button-outer-div {
        margin-top: -2vh;
        padding: 0px 1px 0px 4px;
    }
}

@media (max-width:350px) {
    .upload-file-modal-div {
        height: 80px;
        width: 140px;
    }
}

@media (max-width:280px) {
    .upload-file-modal-div {
        height: 60px;
    }
}

.upload-file-input-file {
    opacity: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin: -17px;
}

.upload-file-modal-div>img {
    position: absolute;
}

.upload-file-modal-div>.sc-aXZVg {
    opacity: 0;
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
    margin: auto;
    border-radius: 10px;
    border: 2px dashed black;
    align-items: center;
    justify-content: center;
    min-width: unset !important;
}