.happy-hours-section-outer {
    /* background-color: white; */
}

.happy-hours-section-inner>.col-lg-4 {
    display: flex;
    flex-direction: column;
}

.happy-hours-card {
    /* margin-top: 10px; */
    margin-bottom: 15px;
    box-shadow: 2px 5px 18px #8d979e33;
    background-color: white;
}

.happy-hours-card-img {
    display: block;
    max-width: 100%;
    aspect-ratio: 1.9;
    width: 100%;
    height: 230px;
}

/* .happy-hours-section-inner {
    padding: 0px 10px;
} */

.happy-hours-card-text-section {
    padding: 5px 10px 0px 10px;
    flex-grow: 1;
}

.happy-hours-card-text-section .service-name-outer {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: .5rem;
    align-items: center;
}

.service-name-outer .service-name {
    margin: 5px 0px 0px 0px;
    font-size: 16px;
    text-align: left;
    font-family: "Mulish", sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
}

.happy-hours-card-img-section {
    position: relative;
}

.happy-hours-card-text-section .service-discount {
    margin-top: 2px;
    white-space: pre-line;
    color: #707373;
    font-size: 13px;
    line-height: 1.6;
}

.happy-hours-card-action-section {
    justify-content: flex-end;
    margin-top: auto;
    padding: 0 8px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.happy-hours-card-action-section .service-card-footer {
    /* margin: 0 8px; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.service-card-footer .price-section {
    display: flex;
    align-items: center;
}

.happy-hours-section-outer .infinite-scroll-component {
    overflow: hidden !important;
}
.service-name-outer .service-name:hover{
   color: var(--sura-text-color) ;
   cursor: pointer;
}
.happy-hours-card-img-section img:hover{
  cursor: pointer;
}

/* ------------------ ribin css ----------------------- */

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid var(--sura-text-color);
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: var(--sura-text-color);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
  