 .content {
     width: auto;
     padding-left: 16px;
     padding-right: 16px;
     width: auto;
 }
  .braches-text {
    color: var(--black-color);
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }
  .Toastify__toast-body > div:last-child {
    flex: 1 1;
    font-size: 14px;
    display: inline-block;
  }
  .branches-buttton {
    padding: 4px 7px !important;
    border: 1px solid var(--heasing-icon-shadow) !important;
  }
  .branches-buttton:hover {
    background: var(--bg-color);
    color: var(--white-text-color) !important;
  }
.action_edit {
     color: var(--sura-text-color);
 }

 .action_delete {
     color: var( --card-btn-text-color);

 }
 .all {
     margin-top: -20px;
     width: 28px;
     background-color: var(--sura-text-color);
     opacity: 2;
     border: none;
     padding: 2px;
 }
 .deactive {
     margin-top: -20px;
     width: 52px;
     background-color: var(--deactive-color);
     margin-left: 88px;
     opacity: 2;
     border: none;
     padding: 2px;
 }
.all-nav-button {
     display: flex;
     align-items: center;
 }


@media only screen and (max-width: 800px) {
     .sidebar {
         display: none;
     }
     .content {
         width: auto;
     }
}
@media only screen and (max-width: 600px) {
     .sidebar {
         display: none;
     }
     .content {
         width: auto;
     }
 }
@media (max-width:350px) {
     .headerFont1 {
         font-size: 12px;
     }
 }
.ant-picker-ok button {
    background: var(--sura-text-color);
    color: white;
    font-weight: bold;
  }
  .ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled,
  .ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled+.ant-picker-time-panel-cell {
    display: none;
  }
  .ant-modal-footer button {
    font-weight: bold;
  }
  .ant-modal-footer .ant-btn-primary {
    background: var(--sura-text-color);
    color: white;
    font-weight: bold;
  }
  .modal-backdrop.fade {
    opacity: 1 !important;
  }
  .modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
  }

  .branch-modal{
    top: 20;
  }