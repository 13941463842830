
.page-not-found-outer-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.page-not-found-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
}
.page-not-found-btn {
  position: absolute;
  bottom: 20px; /* Button ko image ke neeche rakhne ke liye */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #ea83bc;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.page-not-found-img{
  position: relative;
}
.page-not-found-btn{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%,-50%);
}

.page-not-found-btn:hover {
  color: white;
}

@media (max-width: 768px) {
  .page-not-found-btn {
    padding: 8px 20px;
    bottom: 15px; /* Mobile/iPad ke liye thoda adjust */
  }
}

@media (max-width: 420px) {
  .page-not-found-btn {
    padding: 8px 15px;
    bottom: 10px; /* Chhote screen pe aur adjust */
  }
}

