@import url('../src/pages/UserProfile/Style.css');
@import url('../src/pages/ReviewAndRatings/Service/Service.css');
.App-header{
	padding: 300px;
}
.scroll-to-top {
	background-color: #FFA1C6 !important;
	border-radius: 50% !important;
}
.modalnew .ant-modal-footer{
	display: none;
}

.css-dev-only-do-not-override-f7vrd6 .modalnew{
	width: 60% !important;
}

.ant-modal-wrap{
	backdrop-filter: blur(3px);
}