@import "../../assets/styles/style.css";
.user-sidebar-cancel-deletion-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

}
.vendor_menuBarOuterDiv .sidebar-logout{
    position: absolute;
    top:91%
}
.menu-sidebar-header-section .sidebar-logout{
    position: relative;
    top:18px
}
.after-deletion-sidebar {
    margin-top: 56px;
}

.cart-payment-div {
    display: none;
}

.sidebar_outer_container {
    background-color: rgba(247, 247, 249, 1);
}
.vendor_link{
    cursor: pointer;
}
.venodr-sidebar-log-out-section-active-lick{
    margin-top: -2px;
}