.top-review-outer{
    margin-top: -15px;
}

.top-review-shop-row>.col-lg-3,
.top-review-shop-row>.col-sm-12,
.top-review-shop-row>.col-md-4 {
    display: flex;
    flex-direction: column;
}

.box {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top-review-shop-card {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 0 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 0 4px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 4px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.top-review-shop-card>.shop-card-inner {
    padding: 18px;
    flex-grow: 1;
}

/* ----------------------------- image section -------------------------------- */
.deal-of-day-image-section {
    cursor: pointer;
    position: relative;
}

.deal-of-day-image-section>.shop-image>img {
    width: 100%;
    aspect-ratio: 1/1;
    height: 150px;
}

.deal-of-day-image-section>.add-to-favourite-secton {
    position: absolute;
    top: 0;
    right: 4px;
}

.deal-of-day-image-section>.add-to-favourite-secton>i {
    font-size: 23px;
}

.discount-section {
    position: absolute;
    bottom: 5px;
    left: 6px;
}

.discount-section>.discount-section-inner {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 6px 4px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-height: 25px;
    margin-bottom: 6px;
}


.discount-section>.discount-section-inner>.discount-percent {
    line-height: 1 !important;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: var(--sura-text-color);
    margin-left: 5px;
    font-weight: 700 !important;
}

@media (max-width:768px) {
    .deal-of-day-image-section>.shop-image>img {
        width: 100%;
        height: auto;
        object-fit: fill;
        aspect-ratio: 1/1;
        height: 350PX;

    }
}

@media (max-width:450px) {
    .deal-of-day-image-section>.shop-image>img {
        height: 195px;
    }  
}

/* ----------------------------- content section ------------------------------------ */
.deal-of-the-day-content-section {
    margin-top: 12px;
}

.deal-of-the-day-service-heading{
    font-size: 15px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: normal;
    overflow: hidden;
    font-weight: 700;
    line-height: 1.2 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.deal-of-the-day-content-section>.card-main-address-section {
    font-size: 14px;
    line-height: 1.2;
    vertical-align: top;
    color: #999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deal-of-the-day-content-section>.time-section {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.2;
    vertical-align: top;
    color: #999;
}

.deal-of-the-day-content-section>.price-section>.hl05eU>.yRaY8j {
    margin-top: 5px;
    margin-left: 0px;
    font-size: 14px;
    line-height: 1.2;
    vertical-align: top;
    color: #999;
}

/* ---------------------------- footer section ------------------------------ */
.card-main__footer {
    margin-top: auto;
}

.deal-of-day-footer-section {
    background-color: #f6f6f6;
    padding: 0 18px;
}

.deal-of-day-footer-section>.action-section {
    padding: 3px 0;
    display: flex;
    justify-content: space-between;
}

.deal-of-day-footer-section>.action-section>.cart-section>div>span>.bi {
    font-size: 20px;

}

.deal-of-the-day-content-section>.price-section{
    display: flex;
}