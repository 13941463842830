.share-buttons {
  display: flex;
  justify-content: center;
}

.share-button {
  display: flex;
  padding: 5px 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.share-icon {
  font-size: 10px;
}

.bi-whatsapp {
  /* font-size: 1.3rem !important; */
  color: var(--green-color) !important;
}

.bi-twitter {
  /* font-size: 1.3rem !important; */
  color: var(--twitter-icon-color) !important;
}

.bi-facebook {
  /* font-size: 1.3rem !important; */
  color: var(--blue-color) !important;
}

.bi-envelope-fill{
  /* font-size: 1.3rem !important; */
  color: var(--red-color) !important;

}

.bi-line{
  color: darkgreen !important;
}

.share-button:hover {
  background-color:lightgrey; 
}
