.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    cursor: pointer;
    border-color: var(--review-rating-active-btn) !important;
    background-color:var(--review-rating-active-btn) !important;
}

.opening-hours-select-tag-open {
    width: 100%;
    height: 2.6em;
}
.apply-all-label{
    /* font-size: 18px; */
}
.opening-hours-select-tag-open .ant-select-selector {
    border-radius: 6px !important;
}

.openingHourse_CommonClass2 {
    margin-left: 1.5vw;
    margin-top: 0.5vh;
    cursor: pointer;
}

.openign-hourse-span-current-day {
    margin-left: 2vw;
}

.OpeningHoursPolicies_DayWise_Time {
    text-transform: capitalize;
}

.OpeningHours24hoursDiv {
    margin-left: 3vw;
}

@media (max-width:991px) {
    .OpeningHours24hoursDiv {
        margin-left: 0vw;
    }

    .OpeningHoursPolicies_service_my_loacationDiv>.row>.col-lg-4 {
        margin-top: 20px;
    }
    .OpeningHoursPolicies_BuisnesHourOuterDiv>.mt-4,.OpeningHoursPolicies_BuisnesHourOuterDiv>.mt-3{
        margin-top: 0px !important;
    }
}

@media (max-width:758px) {
    .OpeningHours24hoursDiv {
        margin-left: 0vw;
    }
}


.vendor-account-details-input-field {
    border-radius: 6px !important;
}

.openingHourse-day-checkbox {
    height: 1.4em;
    width: 1.4em;
}

.breaktime-select-tag {
    margin-top: -1vh;
}

@media (min-width:575px) {
    .opening-hourse-buisssnes-hourse-outer-row {
        padding: 0px 120px 0px 0px;
    }

}

@media (max-width:500px) {
    .openingHourse-day-checkbox {
        margin-top: 7px !important;
        height: 15px;
        width: 15px;
    }

    .OpeningHoursPolicies_DayWise_Time {
        text-transform: capitalize;
        overflow: hidden;
        width: 350px;
    }

    .OpeningHoursPolicies_BuisnesHourOuterDiv {
        overflow: auto;
    }

    .openign-hourse-span-current-day,
    .opening-hourse-p-tag {
        font-size: 15px;
    }

    .opening-hours-outer-heading {
        font-size: 17px;
    }

    .opening-hours-select-tag {
        background-size: 8px 12px;
        font-size: 12px !important;
        margin-top: 5px;
        padding: .35rem 1.25rem .375rem .0rem;
    }

    .opening-hourse-to-div {
        margin-top: 5px;
        font-size: 15px;
    }

    .vendor-opening-hours-p-tag-inner {
        margin-top: 3px;
        font-size: 15px;
    }

    .OpeningHourse_Common {
        font-size: 13px
    }

    .opening-hourse-cancel-policy-checkbox {
        margin-top: 5px !important;
    }

}

@media (max-width:575px) {
    .vendor-opening-hours-p-tag-inner-div {
        width: 30%;
    }

    .breaktime-select-tag {
        width: 30%;
    }

    .OpeningHoursPolicies_Payment_Option_Div {
        margin-top: 20px !important;
    }

    .OpeningHoursPolicies_Days_valid_after_purchase_inner-div {
        margin-top: 30px !important;
    }
    .OpeningHoursPolicies_BuisnesHourOuterDiv>.mt-2{
        margin-top: 0px !important;
    }
    .OpeningHoursPolicies_Days_valid_after_purchase_inner-div {
        margin-top: 10px !important;
    }

}

@media (max-width:400px) {
    .breaktime-select-tag {
        width: 40%;
    }
}

@media (max-width:341px) {
    .breaktime-select-tag {
        width: 40%;
    }
}

@media (max-width:375px) {
    .openingHourseDayDiv {
        width: 40%;
    }

    .vendor-opening-horse-open-time {
        width: 22%;
    }

    .opening-hourse-to-div {
        width: 13%;
    }

    .vendor-opening-horse-close-time {
        width: 22%;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.openingHourseDayDiv>.form-check-input {
    margin-top: 0px !important;
}

.checkbox-outer>.row>.col-lg-4>.row>.col-1>.form-check-input {
    margin-top: 0px !important;
}

@media (max-width:500px) {
    .openingHourseDayDiv>.form-check-input {
        margin-top: 3px !important;
    }

    .checkbox-outer>.row>.col-lg-4>.row>.col-1>.form-check-input {
        margin-top: 7px !important;
    }

}