.venodr-payout-history-table {
    padding: 5px 0px;
}
.venodr-payout-history-table-row {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}
.CxhGGd{
    font-size: "15px"
}
.vendor-payout-revenue {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.vendor-payout-currency>.hl05eU>.CxhGGd {
    font-weight: 600;
    font-size: 16px;
}
.venodr-payout-history-table-row>.vendor-payout-revenue>.vendor-payout-next-icon>.bi {
    cursor: pointer;
}
.vendor-payout-section {
    padding: 0px 10px;
}