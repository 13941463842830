.book-now-container {
    margin-top: 155px;
    overflow-x: hidden;
    padding: 0px 30px 0px 30px;
}
.book-email {
    height: 2.5em;
    max-width: 100%;
    border: 1px solid var(--book-border-color);
    border-radius: 5px;
}
.book-name {
    height: 2.5em;
    max-width: 100%;
    border: 1px solid var(--book-border-color);
    border-radius: 5px;
}
.yourbooking-details-section-name-div{
    margin: 0px 10px;

}
.yourbooking-details-section-name-div>h6 {
    margin-bottom: 0.1rem;
}

.book-now-p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0em;
}
.book-div3-text {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
}
.book-div3-small {
    font-size: 10px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
}
.book-now-apply-button {
    background: var(--review-rating-active-btn);
    height: 40px;
    border: var(--review-rating-active-btn);
    color: var(--white-text-color);
    width: 80px;
    font-weight: bold;
}

.accordian-input {
    height: 40px;
    width: 73px;
    border: 1px solid var(--book-border-color);
    margin-left: -5px;
    padding: 18px;
}
.accordian-icon {
    font-size: 40px;
}
.book-now-accordion-body {
    padding: 0px 0px 0px 0px;
    margin-left: 0px;
}
.book-now-accordion-button {
    color: var(--review-rating-active-btn);
}
.book-now-accordian-label {
    display: flex;
    justify-content: space-between;
}
.accordian-hr {
    margin: 0.2rem 0 0.5rem 0;
}
.ac-btn .accordion-button::after {
    display: none;
}
.ac-btn button {
    height: 22px;
    margin-top: 10px;
}
.book-now-order {
    text-align: center;
    margin-bottom: 10px;
}
.book-now-order Button {
    background-color: var(--review-rating-active-btn);
    color: var(--white-text-color);
    font-weight: bold;
    height: 40px;
    width: 125px;
    font-size: 17px;
}

.book-now-order Button:hover {
    background-color: var(--review-rating-active-btn) !important;
    color: var(--white-text-color) !important;
    font-weight: bold;
    height: 40px;
    width: 125px;
    font-size: 17px;
}
.payment-checkbox {
    height: 1rem;
    width: 1 rem;
}
.cashback-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--review-rating-active-btn) !important;
}
.cashback-checkbox .ant-checkbox-inner {
    border-color: var(--review-rating-active-btn) !important;
}
.book-div3-price p {
    margin-bottom: 0.5rem;
}
.cart-payment-content .row hr {
    margin: 0.3rem 0rem 0.5rem 0rem;
}
.cart-payment-content>form>.row>.col-lg-6>div>.ant-input::placeholder {
    font: 0.75rem/4 sans-serif !important;
}
.cart-payment-content>form>.row>div>.ant-input::placeholder {
    font: 0.75rem/4 sans-serif !important;
}
.payment-address-err {
    margin-top: 5px;
}
.yourbooking-details-section {
    display: flex;
    gap: 15px;
}
.checkout-date-edit{
    display: flex;
}
.checkout-date-edit>i{
   font-size: 12px;
   color: var(--sura-text-color)
}

@media (max-width:770px) {
    .order-md-2 {
        order: 3;
    }
    .order-md-3 {
        order: 2;
    }
}
@media (max-width:400px) {
    .yourbooking-details-section {
        display: block;
    }
    .yourbooking-details-section-name-div {
        display: flex;
    }
    .yourbooking-details-section-date-div {
        display: flex;
    }
}
.booking-location-outer {
    display: flex;
}
.booking-location-outer-use-my-btn>.btn {
    background: var(--button-bg-color);
    color: white;
    border-radius: 0px;
    width: 100%;
}
.location-btn{
    cursor: pointer;
    color: var(--sura-text-color);
}
.location-btn:hover{
  text-decoration: underline;
}
.booking-location-outer-use-my-btn>.location-btn{
    font-size: 14px;
}
.payment-term-condition{
    font-size: 1em;
    color: var(--sura-text-color) !important;
    text-decoration: none;
}
.payment-term-condition-span-tag{
    font-size: 1em;
}
.yourbooking-details-section-name-div>.icon{
   font-size: 12px;
}


.payment-option{
    box-shadow: 0 2px 9px -2px rgba(50, 71, 92, 0.06), 0 4px 9px 1px rgba(50, 71, 92, 0.04), 0 2px 6px 4px rgba(50, 71, 92, 0.02);
    text-align: center;
    padding: 5px;
    border-radius: 6px;
}
.payment-option-icon{
    width: 80px;
}

.payment-option .ant-radio-wrapper-checked .ant-radio-inner{
    border-color: transparent!important;
    background-color: var(--sura-text-color)!important;
}

.payment-mode-show{
    transition: font-size 3s, color 2s;
}