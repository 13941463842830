.footer {
  background-color: var(--bg-black-color);
  color: var(--white-text-color);
  padding: 23px 10px;
  overflow-x: hidden;
}
.footer-heading {
  color: var(--white-text-color);
}
.certificate-div{
  margin:7%;
}
.main-col{
  display: flex;
}
.footer-hr {
  border-top: 1px solid var(--white-text-color);
}
.footer-paragraph {
  color: var(--white-text-color);
  font-size: 16px;
}
.footer-heading p{
  white-space: nowrap;
}
.footer-contact p {
  margin-bottom: 4px !important;
}
.contact-link a {
  color: var(--white-text-color) !important;
  text-decoration: none;
}
.footer-outer-container a{
  text-decoration: none;
}
hr {
  width: 100% !important;
  display: flex;
}
.app-store{
  width: 100px;
  height: auto;
  cursor: pointer;
}
.social-footer-icon{
  outline: 1px solid white;
  padding: 5px 5.1px 4px 5px;
  border-radius: 50%;
  color: white!important;
  margin: 10px;
}
.social-footer-icon .bi
{
  font-size: 13px!important;
}
.copyRight-footer i{
  color: white!important;
}

.contact-link a:hover {
  color: var(--sura-text-color) !important;
  text-decoration: none !important;
}
.footer-text {
  color: var(--white-text-color);
}
.footer-links-vertical {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.footer-link {
  color: var(--white-text-color);
  transition: color 0.3 ease;
}
.copy-rigth-section .footer-link {
  font-size: 13px;
  margin-left: 10px;
}
.footer-link:hover {
  color: var(--sura-text-color);
  text-decoration: none !important;
}
.footer-outer-container {
  min-height: 20vh;
}
@media (max-width:768px) {
  .footer {
    background-color: var(--bg-black-color);
    color: var(--white-text-color);
    padding: 23px 10px;
    text-align: center;
  }
  .copy-rigth-section {
    font-size: 13px;
    margin-top: 20px;
    bottom: 10px;
  }
  .main-col{
    display: flow;
  }
  .copy-rigth-section{
    display: flex;
    justify-content: center;
  }
}