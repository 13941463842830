/* 
@import "../src/commonCSS/variable.css";
@import url('https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */


/* @import url('./common/'); */
* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.crop-image-file,
.upload-file-img-icon {
  cursor: pointer !important;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--sura-text-color) !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  border-color: var(--sura-text-color) !important;
  background-color: var(--sura-text-color) !important;
}

.h1-font {
  font-size: 40px;
}

.countryCode-class {
  background: white;
}

.venue-name-h5 {
  line-height: 1.15;
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: .5rem;
}

.venue-name-h4 {
  line-height: 1.2;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: .5rem;
}

.accountDeletion_fa_icon_back a{
  color: black !important;
}
.venue-name-h3{
  line-height: 1.15;
  font-weight: 500;
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: .5rem;
}

.disable-btn{
  pointer-events: none;
  opacity: 0.5;
}
.venue-name-h6{
  line-height: 1.15;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 0;
}


.anticon {
  font-size: 16px !important;

}

.cta {
  border: none;
  background: none;
  cursor: pointer;
  float: right;
}

.cta span {
  margin-top: 5px;
  padding-bottom: 7px;
  letter-spacing: 2px;
  font-size: 12px;
  padding-right: 15px;
  color: var(--sura-text-color);
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}



@media (max-width: 767px) {

  .display-service-outer-div,
  .search-display-des-outer-div {
    margin-top: 120px;
    max-width: unset;
  }
}

body .zoom-image {
  transition: transform 0.3s ease;
  overflow: hidden;
}

body .zoom-image:hover {
  transform: scale(3.4);
  position: relative;
  z-index: 1;
}

.inner-div {
  display: flex;
  height: 100%;
  align-items: center;
}

.heading-outer-div {
  text-transform: capitalize;
}

.after-heading-outer-div {
  margin-top: 15px;
}

.user-common-box {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
}

.user-common-box-inner {
  border-radius: 10px;
  background-color: var(--white-btn-bg-color);
  border: 1px solid var(--step-icon-bg);
  padding: 1rem;
}

.user-common-box-inner p {
  color: var(--black-color);
}

/* .user-common-box-inner span{

  color: var(--dark-gray-color)
} */

.user-common-box-inner .inner-box-inner-div {
  padding: 5px;
}

span {
  /* color: var(--span-tag-text-color); */
}

.referral-code .anticon svg {
  color: var(--input-field-color);
}

.user-add-to-cart-inner-div .list-group {
  --bs-list-group-bg: var(--white-color) !important;
}

.form-control,
.ant-select-selection-item,
.ant-input-disabled {
  color: var(--input-field-color) !important;
}

.ant-input-disabled,
.ant-select-disabled {
  background-color: var(--search-btn) !important;
}

.ant-modal-body .ant-rate-star-first>span {
  color: var(--dark-gray-color) !important;
}

.ant-modal-body .form label div i {
  color: var(--input-field-color) !important;
}

.ant-modal-body .form label span,
.ant-modal-body .service-name,
.ant-modal-body .modal-text span,
.ant-modal-body .modal-text div {
  color: var(--input-field-color);
}

.user-inner-content-scrollar {
  /* max-height: calc(100vh - 160px); */
  overflow-y: auto;
  overflow-x: hidden;
}

.user-inner-content-scrollar-after-fix-content {
  /* max-height: calc(100vh - 255px) !important; */
}

.user-inner-content-scrollar-table {
  max-height: calc(100vh - 255px);
  overflow-y: auto;
  overflow-x: auto;
}

.user-inner-content-scrollar-deletion-request {
  max-height: calc(100vh - 201px);
  overflow-y: auto;
  overflow-x: hidden;
}

.user-inner-content-scrollar-table {
  padding: 0px 0px 0px 5px;
}

.home-errorpage {
  aspect-ratio: 2.29 / 1;
  object-fit: fill !important;
  width: 100%;
}

.home-errorpage-vendor {
  height: 100vh;
  object-fit: fill !important;
  width: 100%;
}

/* ------------------------------------ scroller color -------------------- */

.user-inner-content-scrollar::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.user-inner-content-scrollar::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.user-inner-content-scrollar::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.user-inner-content-scrollar::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}

.pick-time-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.pick-time-container::-webkit-scrollbar-thumb{
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.pick-time-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.pick-time-container::-webkit-scrollbar-track{
  background-color: #f0f0f0;
}


.notification-panel::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.notification-panel::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.notification-panel::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.notification-panel::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}

/* ---------------------------- fix content in table like button ------------------------- */
.user-common-box-fix-content {
  padding: 5px -1px 0px 0px;
}

/* --------------------------font size in fix content ----------------------------------------- */
.user-common-box-fix-content .user-booking-tab-outer-div .user-bookings-tab {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  padding: 5px 9px;
  border: 1px solid var(--hotpink-border-color);
  margin-right: 6px;
  border-radius: 15px;
}

.user-booking-tab-outer-div {
  padding: 7px 0;
  font-weight: 600;
}

.user-bookings-tab-de-active:hover {
  color: black !important;
}

@media (max-width:314px) {
  .team-member-working-hour-tab>.AccountSettingHeader-container>.my-profile-section>.user-bookings-tab {
    width: 100% !important;
  }

}

@media (max-width:770px) {
  .home-errorpage {
    aspect-ratio: 1.29 / 1;
  }
}

@media (max-width:420px) {
  .home-errorpage {
    aspect-ratio: unset;
  }
}

.bi-suit-heart-fill {
  color: red !important;
}

/* --------------------------- cursor pointer css ------------------------------------------- */
.cursor-point-class {
  cursor: pointer;
}

.ant-select-selector:hover {
  border-color: lightgray !important;
}

.calendar-col .ant-select-selector:hover {
  border-color: hotpink !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer !important;
}

/* pagination button css  */
.ant-pagination {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

/* --------------------------------------- button class------------------------------ */
.buttonClass {
  color: var(--white-text-color);
  font-size: 18px !important;
  font-weight: bold !important;
  background: var(--bg-color);
}

.buttonClass:hover {
  text-decoration: none
}

.bi-wallet {
  font-size: inherit !important;
}

.bi-person-raised-hand {
  font-size: inherit !important;
}

.fa-sitemap {
  font-size: inherit !important;
}

.whiteSpace {
  white-space: nowrap;
}

.row-box {
  background: var(--row-box-color);
  border-radius: 11px;
  margin: 0;
  margin-bottom: 1.5rem !important;
}

/* ------------------------------------------------------ box heading content ------------------- */
.box-heading-content {
  color: var(--black-color);
  text-decoration: none;
  font-size: 17px;
  font-weight: 700;
}

.box-heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px dotted var(--payout-bottum-border);
  color: var(--box-heading-color);
  align-items: center;
}

.box-content {
  margin-top: 10px;
}

.form-label {
  font-size: 15px;
  margin-bottom: 0.2rem !important;
}

.form-control {
  font-size: 14px !important;
}

/* -------------------------------------- button weight----------------------- */
.btn {
  font-weight: 500 !important;
}

.table {
  text-transform: capitalize;
}

/* ----------------------------------------- processing blur background claass========================== */
/* CartPayment.css */
.processBlur {
  filter: blur(2px);
  pointer-events: none;
}

.CustomSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

/* -------------------------------------------display service scroll------------------- */

.display-service-container-show-data::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.display-service-container-show-data::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color) !important;
  border-radius: 1em !important;
}

.display-service-container-show-data::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color) !important;
}

.display-service-container-show-data::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color) !important;
}

/* -------------------------------------------display service scroll end------------------- */
.bi {
  cursor: pointer !important;
}

#root {
  overflow-y: hidden;
}

.cart-btn-outer {
  border-radius: 6px;
  height: 38px;
}

.cart-icon-btn {
  flex-shrink: 0;
  border-radius: 5px;
  background-color: var(--red-color) !important;
  border: 1px solid !important;
  color: var(--red-color) !important;
  background-color: var(--white-btn-bg-color) !important;
  padding: 0px 3px 1px 3px !important;
}

.cart-btn-text {
  display: block;
  font-size: 6px;
}

.disabled-icon {
  cursor: none !important;
  opacity: 0.4;
  pointer-events: none !important;
}

.disable-icon-outer {
  cursor: none !important;
  pointer-events: none !important;

}

.inabled-icon {
  cursor: pointer !important;
}

.highcharts-credits {
  display: none;
}

input::placeholder {
  font: 0.93rem/4 sans-serif;
  color: var(--placeholder-color) !important;
}

.home-content-outr-div {
  margin-top: 127px;
}

input::placeholder {
  font: 0.65rem/4;
}

select::placeholder {
  font: 0.65rem/4;
}

@media (max-width:767px) {
  .home-content-outr-div {
    margin-top: 65px;
  }
}

@media (max-width:421px) {
  .home-content-outr-div {
    margin-top: 120px;
  }
}

@media (max-width:368px) {
  .befor-log-in-outer-home-div {
    margin-top: 118px !important;
  }
}

@media (min-width:1126px) {
  .deletionRequest-user-bradcrumb-outer {
    margin-top: 180px !important;
  }

  .deletionRequest-not-show-filter {
    margin-top: 180px !important;
  }

  .deletion-request-home {
    margin-top: 165px;
  }
}

.vendor-deletionRequest-user-header {
  margin-top: 140px;
}

@media (max-width:1125px) and (min-width:768px) {
  .user-my-profile {
    margin-top: 15px !important;
  }

  .user-outer-div {
    margin-top: 120px;
  }

  .deletionRequest-user-header {
    margin-top: 150px !important;
  }

  .deletionRequest-user-bradcrumb-outer {
    margin-top: 190px !important;
  }

  .deletionRequest-not-show-filter {
    margin-top: 190px !important;
  }

  .deletion-request-home {
    margin-top: 175px;
  }
}

@media (max-width:991px) and (min-width:768px) {
  .deletionRequest-user-bradcrumb-outer {
    margin-top: 175px !important;
  }

  .deletionRequest-not-show-filter {
    margin-top: 175px !important;
  }

  .deletion-request-home {
    margin-top: 160px;
  }
}

@media (max-width:767px) {
  .deletionRequest-user-header {
    margin-top: 140px;
  }

  .deletionRequest-user-bradcrumb-outer {
    margin-top: 195px !important;
  }

  .deletionRequest-not-show-filter {
    margin-top: 170px !important;
  }

  .deletion-request-home {
    margin-top: 155px;
  }
}

@media (max-width:373px) {
  .deletionRequest-user-header {
    margin-top: 160px;
  }

  .deletionRequest-user-bradcrumb-outer {
    margin-top: 225px !important;
  }

  .deletionRequest-not-show-filter {
    margin-top: 195px !important;
  }

  .deletion-request-home {
    margin-top: 187px;
  }
}

@media (max-width:364px) {
  .user-outer-div {
    margin-top: 150px;
  }

  .deletionRequest-user-bradcrumb-outer {
    margin-top: 285px !important;
  }

  .deletionRequest-not-show-filter {
    margin-top: 260px !important;
  }

  .deletion-request-home {
    margin-top: 250px;
  }
}

@media (max-width:575px) {
  .cart-btn-outer {
    height: 36px;
  }

  .cart-icon-btn {
    padding: 0px 3px 0px 3px !important;
  }

  .cart-icon-btn>span>.mt-1 {
    margin-top: 0rem !important;
  }

  .cart-icon-btn>span>.fa-cart-plus {
    font-size: 12px;
  }
}

/* ----------------------------------botton on hover---------------------------- */
.btn-on-hover:hover {
  background: var(--category-hover-color);
  border: 1px solid var(--category-hover-color);
  color: #FFFFFF !important;
}

.sing-btn-on-hover:hover {
  font-size: 16px;
  font-weight: 400;
  background: var(--category-hover-color);
  padding: 3.5px 6px;
  border: 1px solid var(--category-hover-color);
  border-radius: 5px;
  color: #FFFFFF !important;
}

/* ------------------------------- description three line me hi dikhe---------------------- */
.hover-description {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Arial';
}

.hover-beuty-description {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Arial';
}

.input-field-err {
  color: red;
  text-transform: capitalize;
}

.common-description-service {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.common-description-service1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width:575px) {

  .display-service-container>.common-description,
  .spa-OuterContainer>.p-2>.common-description {
    display: -webkit-box !important;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
  }
}

@media (max-width:421px) {
  .user-outer-div {
    margin-top: 120px;
  }

  .deletionRequest-user-header {
    margin-top: 200px !important;
  }
}

/* ------------------------------------- toast message box------------------------- */
.Toastify__toast-container--top-right {
  top: 1em;
  right: 0.5em !important;
}

@media (max-width:575px) {
  .Toastify__toast-container--top-right {
    top: 1em;
    right: -0.2em !important;
  }
}

.vendor_review_review::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.vendor_review_review::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color) !important;
  border-radius: 1em !important;
}

.vendor_review_review::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color) !important;
}

.vendor_review_review::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color) !important;
}

.single-column-menu {
  white-space: normal;
}

.web-navbar-language-image-tag img {
  border-radius: 50%;
  height: 26px !important;
  width: 26px !important;
}

.language {
  margin-bottom: 5px;
}

.actions>.nvabar-profile {
  margin-bottom: 5px;
}

.web-navbar-language-image-tag,
.web-view-filter-icon,
.cursor-point-class {
  cursor: pointer;
}

.currency-display-d-price {
  color: black;
}

.custom-check-box-ant {
  margin: 0;
  padding: 0
}

@media (max-width:991px) and (min-width:767px) {
  .after-category-new-div {
    margin-top: 140px !important;
  }
}

.view-all-function-class {
  cursor: pointer !important;
}

.service-name {
  text-transform: capitalize;
  font-weight: bold;
}

input:-webkit-autofill {
  background-color: #eaeaeb !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #6c6c6c !important;
}

/* --------------------- book now container css -------------------------- */
@media (min-width:1125px) {
  .book-now-container {
    margin-top: 150px;
  }
}

@media (max-width:1124px) and (min-width:768px) {
  .book-now-container {
    margin-top: 160px;
  }
}

.discount-percent-div {
  font-weight: bold;
  color: var(--sura-text-color);
}

.display-service-price-outer {
  display: flex;
  gap: 10px;
}

@media (max-width:767px) {
  .book-now-container {
    margin-top: 120px;
  }

}

@media (max-width:767px) and (min-width:575px) {
  .book-now-container {
    margin-top: 120px;
  }

  .display-service-price-outer {
    display: unset !important;
  }
}

/* ------------------------------- payment checkout page ------------------------------------ */

.ant-rate-star:not(:last-child) {
  font-size: 16px !important;
  margin-inline-end: 0 !important;
}

/* ------------------------------- font style , family , size apply here ------------------------------------ */
.form-control {
  font-family: 'Arial';

}

.heading-font {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 45px;
  text-transform: capitalize;
}

.paragraph-font {
  font-family: 'Arial';
  font-size: 20px;
}

.button-font-and-cart-section {
  font-family: 'Arial' !important;
  font-size: 20px !important;
  text-transform: capitalize;
}
.countryCode-class {
  background: white !important;
  border: 1px solid #6d6f6d2b !important;
  border-radius: 6px !important;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  padding-left: 9px !important;
  height: 2.1rem;
}
.countryCode-class {
  background-color: #f9f9f9;
  appearance: none;
  outline: none;
}
.form-control:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color:var(--white-color);
  color:var(--input-field-color)!important;
}
.stdropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white;
  max-height: 138px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 6px;
}
.stdropdown-tool {
  display: none;
}
.cart-section-paragraph {
  font-family: 'Arial';
  font-size: 16px;

}

.read-more-font {
  font-family: 'Arial';
  font-size: 16px;
  text-transform: capitalize;
}

.button-font-and-color {
  font-family: 'Arial';
  font-size: 16px;
  font-weight: 400;
  color: var(--category-hover-color);
}

.near-me-btn span:hover {
  color: var(--white-text-color) !important;
}

@media (max-width:480px) {
  .heading-font {
    font-size: 20px;
  }

  .paragraph-font {
    font-size: 14px;
  }

  .outer-footer-email-field::placeholder {
    font-family: 'Arial';
    font-size: 14px !important;
  }
}


@media (min-width:545px) {
  .add-to-cart-modal div .ant-modal-content {
    width: 85%;
  }
}

@media (max-width:545px) {

  .book-now-modal-calendar-col-9>div>.ant-picker-dropdown>.ant-picker-panel-container {
    width: 100%;
  }
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none;
}

.ant-select-selector,
.ant-select-selector:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none;
}


.home-errorpage {
  margin-top: -50px;
}

@media (max-width:405px) {
  .custom-modal {
    width: auto !important;
  }
}

@media (max-width:421px) {
  .home-errorpage {
    margin-top: -60px;
  }
}

.ant-btn-primary:disabled {
  opacity: .5 !important;
  color: white;
}

input:focus-visible {
  outline-offset: 0px;
  outline: none !important;
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border: 1px solid #d9d9d9 !important;

}
.cart-payment-image {
  height: 70px !important;
  width: 110px !important;
}
.loader-blog-section {
  margin-top: 250px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 84px;
  border: 1px solid #6d6f6d2b !important;
  border-radius: 6px !important;
  width: 100%;
}
.finace-IntlTelInput-form .separate-dial-code input {
  height: 2.2rem;
}
/* -------------------------------- product card .css -------------------- */

.healthTreatment_showListProudctDetailsDiv {
  background-color: whitesmoke;
  text-align: center;
}

.healthTreatment_ShowListTitle {
  color: rgba(238, 10, 137, 1);
  font-weight: 400;
}

.productCard_Paragraph {
  font-size: 12px;
}

.healthTreatment_ShowListDiscountPrice {
  color: rgba(255, 112, 0, 1);
}

.healthTreatment_ShowRatingImage {
  width: 30%;
}

.healthTreatment_showListBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productcard-viewmore-button {
  font-weight: bold;
  background-color: rgba(238, 10, 137, 1);
  color: white;
  height: 3em;
  width: 50%;
  text-align: center;
  border: none;
  border-radius: 10px;
}

.productCardImage {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* ------------------------------------ product preview .css file css ----------------------- */
.fitAndAct_showListRightDiv,.fitAndAct_showListLeftDiv{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fitAndAct_ShowListLeftTitle2 h5 {
  font-size: 15px;
  font-weight: 400;
}

.fitAndAct_showListLeftImg {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.fitAndAct_ShowListLeftTitle {
  color: rgba(238, 10, 137, 1);
  font-weight: 400;
}

.fitAndAct_ShowListLeftTitle2 {
  color: black;
  font-weight: 400;
  text-align: justify;
}

@media (max-width:991px) {

  .fitAndAct_showListLeftDiv>.mt-4,
  .fitAndAct_showListLeftDiv>.mt-3 {
    margin-top: 0.2rem !important;
  }
 .fitAndAct_ShowListLeftTitle h3{
  font-size: 19px;
  font-weight: 500;
 }
 .fitAndAct_ShowListLeftTitle h4{
  font-size: 17px;
 }
}

@media (max-width: 768px) {
  .fitAndAct_showListDiv>.fitAndAct_showListLeftDiv>.fitAndAct_ShowListLeftTitle2{
    margin-top: -0.5rem !important;

  }
  .col-5.fitAndAct_showListLeftDiv {
    flex: 0 0 100%;
  }

  .col-7.fitAndAct_showListRightDiv {
    margin-top: 5vw;
    flex: 0 0 100%;
  }

  .row.fitAndAct_showListRightProductInfoDiv {
    flex-direction: column;
  }
}

.best-seller-left-image-div{
  height: 50%;
  width: 100%;
}

@media (min-width: 1350px) {
  .best-seller-left-image{
    height: 302px!important;
    width: 100%;
  }
}
@media (max-width: 1351px) {
  .best-seller-left-image{
    height:100%!important;
    width: 100%;
  }
}


.recommended-section-view-btn>.nbest-seller-ear-me-btn{
  border: none !important;
} 
.cancel-policy-desc>.description_in_thai>pre{
  overflow: hidden;
}

.confirm-btn-disable{
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.confirm-btn-disable{
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.user-profile-form-image-croper>label {
  position: relative;
  display: flex;
  align-items: center;
  border: dashed 2px #0658c2;
  padding: 8px 16px 8px 8px;
  cursor: pointer;
  flex-grow: 0;
  height: 1350px !important;
  min-width: 135px !important;
  max-width: 135px !important;
  border-radius: 50% ;
  opacity: 0;
}

.top-heading .tdclass-number {
  white-space: nowrap;
}


/* --------------------------- Intl Mobile Input Custom Css--------------- */

.intl-tel-input .flag-container .arrow{
  font-size: 11px !important;
  margin-left: 5px;
}


/* .vendor-team-list-modal>label {
  border-radius: 50%;
  height: 80px;
  opacity: 0;
  width: 80px;
} */