.vendor-header-payout-summary>.user-common-box>.heading-inner-box>.heading-user-profile-outer-div>.braches-text>.bi{
    cursor: pointer;
}
.vendor-payout-summary{
    display: flex;
    justify-content: space-between;
}
.vendor-payout-summary>.user-bookings-tab{
    cursor: default;
}
.vendor-payout-summary>.vendor-payout-summary-download-icon{
    cursor: pointer;
}
.vendor-sumary-payout-outer-div>.row>.col-lg-6>.finance-inner-cart-div>.box-heading{
    font-weight: 700;
    font-size: 17px;
}
.vendor-payout-summary-booking-compelete,.vendor-payout-summary-booking-canceled,.vendor-payout-summary-booking-expire{
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}

.payout-summary-row>.col-lg-6>.company-details-inner{
    min-height: auto;
}
.payout-summary-daily-transaction{
    margin-top: 20px;
}
.payout-summary-daily-transaction{
    padding: 0rem;
}
.payout-summary-daily-transaction-heading{
    display: flex;
    justify-content: space-between;
}
.payout-summary-daily-transaction-inner{
    background-color: var(--white-btn-bg-color);
    padding: 0.7rem 0.7rem 0.1rem 0.7rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
.payout-summary-daily-transaction-inner>.payout-summary-daily-transaction-heading{
    font-size: 17px;
    font-weight: 700;
}
.payout-summary-daily-transaction-icon{
    cursor: pointer;
}
.daily-transaction-heading-active{
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);

}
.daily-transaction-details-outer{
    padding: 10px  0px 0px 0px;
}
table>thead>tr{
    white-space: nowrap;
}
.download-btn-disabled{
    opacity: 0.5;
    pointer-events: none;
}
@media (max-width:768px) { 
    .daily-transaction-details-outer{
        overflow: scroll;
    }
}
@media (max-width:575px) {
    .payout-summary-row>.col-lg-6>.company-details-inner{
        margin-top: 20px;
    }
    .vendor-payout-summary>.user-bookings-tab{
        font-size: 15px !important;
    }
    .venodr-payout-history-table-row>.vendor-payout-monthName{
        font-size: 15px;
    }
    .venodr-payout-history-table-row>.vendor-payout-revenue>.vendor-payout-currency>.hl05eU>.Nx9bqj{
        font-size: 14px !important;
        font-weight: 600;
    }
}
.vendor-dashbord-price-td>.hl05eU>.Nx9bqj{
    white-space: nowrap;
}