.App {
	height: 100vh;
	display: flex;
	transition: background 500ms ease-in;
}
.CustomSpinner {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999; /* Ensure the spinner appears on top */
  }
  
.App.inverted {
	background: #131313;
}

.App>div {
	margin: auto;
}
.loading-spinner{
	position: fixed;
	top: 50%;
	left: 60%;
}
.App.inverted .Spinner .spinner-dot {
	background-color: var(--white-btn-bg-color);
}

.App button {
	position: fixed;
	top: 6px;
	right: 6px;
	border: 0;
	padding: 12px 32px;
	font-size: 14px;
	background: var(--header-box-shadow-menu);
}

body.inverted {
	background: #131319;
}

.Spinner {
	height: 29px;
	margin: auto;
	display: flex;
	opacity: 0;
	justify-content: center;
	animation-name: fadeIn;
	animation-duration: 1200ms;
	animation-fill-mode: forwards;
}
.Spinner.SpinnerDots .spinner-dot {
	animation-name: dance;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	height: 12px;
	width: 12px;
	background-color: #242429;
	margin: 3px;
	border-radius: 12px;
}
.Spinner.SpinnerDots .spinner-dot:nth-child(1) {
	animation-delay: 0;
}
.Spinner.SpinnerDots .spinner-dot:nth-child(2) {
	animation-delay: 333ms;
}
.Spinner.SpinnerDots .spinner-dot:nth-child(3) {
	animation-delay: 666ms;
}
.Spinner.SpinnerCircle .spinner-dot {
	width: 32px;
	height: 32px;
	background: #232329;
	border-radius: 50px;
	position: absolute;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.Spinner.SpinnerCircle .spinner-dot:nth-child(2) {
	animation-name: scaleDance;
	animation-delay: 900ms;
}
.Spinner.SpinnerDotsScale .spinner-dot {
	width: 12px;
	height: 12px;
	background: #232329;
	border-radius: 50px;
	margin: 3px;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.Spinner.SpinnerDotsScale .spinner-dot:nth-child(1) {
	animation-name: explode;
	animation-delay: 0;
}
.Spinner.SpinnerDotsScale .spinner-dot:nth-child(2) {
	animation-name: explode;
	animation-delay: 250ms;
}
.Spinner.SpinnerDotsScale .spinner-dot:nth-child(3) {
	animation-name: explode;
	animation-delay: 500ms;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes scaleDance {
	0% {
		transform: scale(1);
		opacity: 0.6;
	}
	50% {
		transform: scale(1.7434);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes explode {
	0% {
		transform: scale(1);
		opacity: 0.4;
	}
	50% {
		transform: scale(1.25);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0.4;
	}
}
@keyframes dance {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(12px);
	}
	100% {
		transform: translateY(0);
	}
}