.aboutus-img-div {
  object-fit: cover;
}
.aboutus-img-div img {
  width: 100%;
  height: 389px;
  object-fit: cover;
  object-position: 50% 50%;
}
.title-business {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  margin: auto;
}
.aboutus-title {
  position: absolute;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 3vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: var(--team-card-bg-color);
  transform: translate(-12px, 181px);
}
.sub-heading-content {
  font-weight: 550;
  font-weight: bold;
}
@media screen and (max-width:450px) {
  .aboutus-title,
  .title-business {
    font-size: 20px;
  }
}
.about-img-div img,
.about-sub-section img {
  width: 350px;
  height: 300px;
  background-size: cover;
  object-fit: cover;
  object-position: 50% 50%;
}
@media (min-width:1125px) {
  .term-condition-outer-container {
    margin-top: 150px;
  }
}
@media (max-width:1124px) and (min-width:768px) {
  .term-condition-outer-container {
    margin-top: 160px;
  }
}
@media (max-width:767px) {
  .term-condition-outer-container {
    margin-top: 120px;
  }
}