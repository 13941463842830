@import "../../../assets/styles/style.css";

.useredit-container input,
.useredit-container select {
    border-radius: 6px;
}

::placeholder {
    font-size: 14px;
}

.useredit-container .useredit-deletebtn {
    width: 200px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: var(--card-btn-color);
    border: 1px solid;
    color: var(--card-btn-text-color);
    background-color: var(--white-btn-bg-color);
}

.vendor-delete-account-btn {
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid;
    color: #FF0000 !important;
    background-color: white;
    border-color: #FF0000 !important;
}
.vendor-delete-account-btn:hover{
    text-decoration: none !important;
}
.hide-file {
    opacity: 0;
}

.useredit-profilebox .edit-profile .reactEasyCrop_Container {
    width: 100%;
    height: 125px !important;
}

.team-member-modal .edit-profile .reactEasyCrop_Container {
    width: 100%;
    height: 100px !important;
}

.avatar {
    vertical-align: middle;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.useredit-container h5 {
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-align: left;
}

.useredit-profilebox .card {
    height: 230px;
    flex-shrink: 0;
    border-radius: 10px;
    background:var(--inner-background-db);
    text-align: center;
}

.useredit-profilebox h5 {
    color:var(--black-color);
    font-size: 22px;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 0.44px;
    text-transform: capitalize;
}

.useredit-container .custom-file-label {
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    background: var(--bg-color);
    color: var(--white-text-color);
    cursor: pointer;
}

.icon {
    margin-right: 5px;
}

.useredit-form {
    left: 0;
    flex-shrink: 0;
    border-radius: 10px;
    background:var(--inner-background-db);
    border: none;
}

.useredit-container .icon {
    position: absolute;
    margin-top: -4% !important;
    margin-left: 50% !important;
    background-color:var(--inner-background-db);
    width: 19.252px;
    height: 19.252px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    padding: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.useredit-container .icon::before {
    cursor: pointer;
    content: "+";
    color: var(--black-color);
}


.date-picker-container {
    position: relative;
}

.rmdp-input {
    height: 37px !important;
    width: 410px !important;
}

.row {
    --bs-gutter-x: 1rem;
}

.userprofileform-save-button,
.userprofileform-save-button:hover {
    color: white !important;
    background: var(--bg-color);
    border: none !important;
}

.userprofileform-cancel-button {
    flex-shrink: 0;
    border-radius: 5px;
    background-color: #FF0000;
    border: 1px solid;
    color: #FF0000;
    background-color: white;
}

.userprofileform-cancel-button:hover {
    color: rgba(255, 0, 0, 1) !important;
    border: 1px solid;
    background-color: #FF0000;
    background-color: white;
}

.user-profile-date-picker-ant {
    border-radius: 6px !important;
}

.vendor-profile-right-part {
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    border-radius: 5px;
    outline: 1px solid gainsboro;
    background: #FFF;

}

.vendor-profile-date-picker-div .ant-picker .ant-picker-input input {
    font-weight: 400;
}

.form-select:focus {
    border: none;
    outline: none;
}

@media (max-width: 1200px) {
    .useredit-profilebox {
        height: auto;
    }
}

@media (max-width: 996px) {
    .useredit-container .useredit-deletebtn {
        width: 70% !important;
        margin-left: -20% !important;
    }
}

@media (max-width: 870px) {
    .useredit-container .useredit-deletebtn {
        font-size: 15px;
    }

    .useredit-form label {
        font-size: 15px;
    }

    .rmdp-input {
        height: 37px !important;
        width: 26vw !important;
    }
}

@media (max-width: 670px) {
    .rmdp-input {
        height: 37px !important;
        width: 53vw !important;
    }
}


@media (min-width:575px) {
    .vendor-my-profile-button-div {
        justify-content: flex-end;
    }
}

@media (max-width:380px) {
    .vendor-my-profile-mobile-number-row .col-6 {
        width: 100%;
    }

    .vendor-my-profile-div-60-1 {
        width: 100%;
    }

    .vendor-my-profile-div-6-2 {
        width: 100%;
    }

    .vendor-my-profile-div-6-2 {
        margin-top: 15px;
    }
}

.vendor-my-profile-date-picker-field {
    height: 37px;
}

.vendor-my-profile-date-picker-field {
    border-radius: 6px !important;
}

@media (min-width:1125px) {
    .vendoraccountdetails_outerDiv {
        margin-top: 60px;
    }
}

@media (max-width:1124px) and (min-width:768px) {
    .vendoraccountdetails_outerDiv {
        margin-top: 65px;
    }
}

@media (max-width:767px) {
    .vendoraccountdetails_outerDiv {
        margin-top: 55px;
    }
}

.card {
    background: var(--inner-background-db);
    border-radius: 5px;
    outline: 1px solid gainsboro;
}

.vendor-my-profile-date-picker-field {
    height: 2.5em !important;
}

@media (max-width:370px) {
    .responsive-gender {
        width: 100%;
    }
}