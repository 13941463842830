.shop-cart-dd-service {
    position: relative;
    padding: 3px;
    border-radius: 5px;
    outline: 1px solid gainsboro;
    text-transform: capitalize;
}
.share-fav-icon {
    position: absolute;
    padding-top: 4px;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
}
.service-image-div {
    padding: 10px;
}
.shop-card-heart {
    color: var(--red-color);
    margin-right: 5px;
}
.cart-image {
    display: flex;
}
.price-child {
    display: flex;
    justify-content: space-between;
}
.view-button {
    margin-top: 5px;
    align-self: flex-end;
}
.name-star-time-share {
    display: flex;
    align-items: center;
}
.shop-card-share {
    color: var(--sura-text-color);
    display: inline-block;
}
.view-service-button {
    position: absolute;
    bottom: 13px;
    right: 10px;
    display: flex;
    align-items: center;
}
.cart-name-start-time-child p {
    margin-bottom: 0px;
}
.service-card-price2-new {
    font-size: 14px;
}

.cart-name-start-time-child>*:first-child {
    padding-top: 8px;
}
.service-image-div img {
    height: 100px;
    width: 100px;
    border-radius: 5px;
}
.cart-name-start-time-child>*:nth-child(2) {
    font-size: smaller;
}

.display-service-button1 {
    background-color: var(--sura-text-color) !important;
    color: var(--white-text-color) !important;
    padding: 7px;
    height: 38px;
}

.display-service-button1 :hover {
    background-color: var(--sura-text-color) !important;
    color: var(--white-text-color) !important;
}
.review-service-heading:hover{
  color: var(--sura-text-color);
}
@media screen and (max-width: 822px) {
    .service-image-div img {
        height: 75px;
        width: 75px;
        border-radius: 5px;
    }
    .service-image-div {
        padding-top: 20px;
    }
    .view-service-button {
        position: absolute;
        bottom: 5px;
        right: 10px;
        display: flex;
        align-items: center;
    }
    .service-card-price2-new {
        font-size: 12px;
        color: var(--sura-text-color);
    }

}
@media screen and (max-width: 700px) {
    .service-image-div img {
        height: 75px;
        width: 75px;
        border-radius: 5;
    }
    .service-image-div {
        padding-top: 20px;
    }
    .view-service-button {
        position: absolute;
        bottom: 5px;
        right: 10px;
        display: flex;
        align-items: center;
    }
    .cart-name-start-time-child>*:nth-child(2) {
        font-size: x-small;
    }
    .cart-name-start-time-child>*:nth-child(3) {
        font-size: 12px;
    }
    .service-card-price2-new {
        color: var(--sura-text-color);
        font-size: 12px;
    }


    .shop-card-share {
        color: var(--sura-text-color);
        font-size: 12px;
        display: inline-block;
    }
    .shop-card-heart {
        color: var(--red-color);
        font-size: 12px;
    }
}

@media screen and (max-width: 576px) {
    .shop-cart-dd-service {
        position: relative;
        padding: 3px;
        box-shadow: 0 0 5px var(--secondary-color);
        text-transform: capitalize;
        border-radius: 10px;
    }
    .share-fav-icon {
        position: absolute;
        padding-top: 4px;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
    }
    .service-image-div {
        padding: 20px;
    }
    .shop-card-heart {
        margin-right: 5px;
    }
    .cart-image {
        display: flex;
    }
    .price-child {
        display: flex;
        justify-content: space-between;
    }
    .view-button {
        margin-top: 5px;
        align-self: flex-end;
    }
    .name-star-time-share {
        display: flex;
        align-items: center;
    }
    .shop-card-share {
        font-size: 18px !important;
        color: var(--sura-text-color);
        display: inline-block;
    }
    .view-service-button {
        position: absolute;
        bottom: 7px;
        right: 10px;
        display: flex;
        align-items: center;
    }
    .cart-name-start-time-child p {
        margin-bottom: 0px;
    }
    .service-card-price2-new {
        color: var(--sura-text-color);
    }

    .cart-name-start-time-child>*:first-child {
        padding-top: 8px;
    }
    .service-image-div img {
        height: 95px;
        width: 95px;
        border-radius: 5px;
    }
    .cart-name-start-time-child>*:nth-child(2) {
        font-size: smaller;
    }
    .shop-card-heart {
        color: var(--red-color);
        font-size: 20px;
    }
}

@media screen and (max-width: 442px) {
    .service-image-div {
        padding: 10px;
    }
    .cart-name-start-time-child>*:nth-child(2) {
        font-size: x-small;
    }
    .cart-name-start-time-child>*:nth-child(3) {
        font-size: 12px;
    }
    .service-card-price2-new {
        color: var(--sura-text-color);
        font-size: 12px;
        width: 100%;
    }

}

@media screen and (max-width:356px) {
    .view-service-button {
        position: inherit;
        bottom: 7px;
        right: 10px;
        display: flex;
        align-items: center;
    }
    .display-service-price-outer {
        width: 100%!important;
    }

    .view-service-button .display-service-button1 {
        background-color: var(--sura-text-color);
        color: var(--white-text-color);
        width: 70px;
        padding: 4px;
        height: 25px;
        font-size: 11px;

    }

    .shop-card-share {
        color: var(--sura-text-color);
        font-size: 16px;
        padding: 2px;
        display: inline-block;
    }

    .shop-card-heart {
        color: var(--red-color);
        font-size: 16px;
    }

    .view-service-button {
        position: inherit;
        bottom: 7px;
        right: 10px;
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    .view-service-button button {
        width: 70%;
        padding: 0px;
    }

}

@media screen and (min-width: 982px) and (max-width:1240px) {
    .service-image-div img {
        height: 80px;
        width: 80px;
        border-radius: 5px;
    }

    .service-image-div {
        padding-top: 16px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .service-card-price2-new {
        color: var(--sura-text-color);
        font-size: 12px;
    }

    .view-service-button {
        position: absolute;
        bottom: 4px;
        right: 10px;
        display: flex;
        align-items: center;
    }
}


@media (max-width:575px) {
    .cart-image {
        display: block;
        justify-content: center;
    }

    .service-image-div {
        padding: 10px 0px 0px 0px;
        display: flex;
        justify-content: center;
    }

    .favourite-service-organisation-name {
        display: flex;
        justify-content: center !important;
    }

    .favourite-service-rating,
    .shop-card-address {
        display: inline-block;
        vertical-align: middle;
        justify-content: center;
    }
    .favourite-service-rating-and-time-div{
        display: flex;
        justify-content: center;
    }
    .favourite-service-organisation-name{
        padding-bottom: 0px !important;
    }
    .review-service-heading{
        margin-bottom: 0px !important;
    }
    .favourite-service-rating-and-time-div>span{
        margin-left: 10px;
        
    }
    favourite-service-rating-address >.favourite-shop-price>.service-card-price2-new>.display-service-price-outer{
        display: flex !important;
        justify-content: center !important  ;
    }
}

@media (max-width:356px) {
    .favourite-service-rating-and-time-div{
        display: flex;
        justify-content: center;
    }
    .price-child{
        display: flex;
        justify-content: left;
    }
    .shop-card-address{
        margin-top: 3px;
    }
    .view-service-button{
       margin-top: 5px;
    }
}