
.display-service-content-outer {
  background: var(--offer-header-bg-color);
  padding: 5px 17px;
}
.fiter-div {
  height: 175vh !important;
  padding: 0px 10px 10px 10px;
  word-wrap: break-word;
  height: auto;
  overflow: auto;
  outline: 1px solid gainsboro;
  background: #fff;
  border-radius: 5px;
}
.sidebar-filter-visible-state-content{
  display: flex;
  gap: 20px;
  font-weight: bold;
  font-size: 16px;
}

.display-service-service-info-outer-div-col {
  padding: 3px 4px;
}

.common-description-service {
  text-align: justify;
}

.showFeaturedServiceTag {
  font-size: 13px;
}

.showFeaturedService {
  font-weight: 700;
}

.filter-header {
  position: sticky;
  margin-bottom: 10px;
}

.shop-detail-div {

  padding: 0px 10px 10px 10px;
  word-wrap: break-word;
  text-transform: capitalize;
  outline: 1px solid gainsboro;
  border-radius: 5px;

}

.display-service-container {
  background: var(--team-card-bg-color);
  height: auto;
  padding: 15px;
  border-radius: 5px;
}

.display-service-outer-div {
  margin-top: 10rem;
}

.accordion-button:not(.collapsed) {
  background-color: var(--white-btn-bg-color);
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-header-icon {
  font-size: 10px;
}

.display-service-heading {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  color: var(--black-color);
  cursor: pointer !important;
  text-transform: capitalize;
}

.display-service-heart-icon {
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
}

.spinner {
  margin-left: 50%;
}

.menu-icon {
  display: none;
}

.display-service-location {
  width: 130%;
  font-size: 13px;
  position: relative;
  bottom: 6px;
  color: var(--black-color);
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  bottom: 9px;
 

}

.display-service-start {
  font-size: 16px;
  color:var(--star-color);
  position: relative;
}

.display-service-clinic-div1 {
  outline: 1px solid gainsboro;
  background-color: white;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;

}

.display-service-clinic-div1>div>span>.hl05eU>.yRaY8j {
  margin-left: 0px;

}


hr {
  width: 100%;
  display: flex;
}


.display-service-button {
  background-color: var(--sura-text-color);
  color:var(--white-text-color);
}

.display-service-button:hover {
  background-color: var(--sura-text-color) !important;
  color:var(--white-text-color) !important;
}

.display-service-sidebar {
  background: var(--team-card-bg-color);
  height: auto;
  padding: 15px;
  border-radius: 10px;
}

.display-service-sidebar-row1 {
  display: flex;
  justify-content: center;
}

.display-service-sidebar-container1 {
  background-color:var(--white-btn-bg-color);
  /* border-bottom: 1px solid var(--dark-gray-color); */
}

.display-service-sidebar-container2Outer {
  background-color:var(--white-btn-bg-color);
  max-height: 250px;
}

.display-service-sidebar-container3 {
  background-color:var(--white-btn-bg-color);
  margin-top: 20px;
  height: 250px !important;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
}

.display-service-sidebar-container2 {
  position: relative;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.display-service-sidebar-container3Outer {
  background-color:var(--white-btn-bg-color);
}

.ant-checkbox-wrapper {
  white-space: nowrap;
}

.display-service-sidebar-container3 {
  position: relative;
  max-height: 169px;
  overflow-y: auto;
}

.display-service-location-vn {
  margin-left: -10px;
}

.display-service-sidebar-container3 {
  background-color:var(--white-btn-bg-color);
  margin-top: 20px;
  height: 250px !important;
  overflow-y: auto;
  position: relative;
}

.display-service-sidebar-container2-row {
  display: flex;
  justify-content: space-between;
}


@media screen and (max-width:1200px) {
  .display-service-heading {
    font-size: 16px;
    font-weight: bold;
  }

  .display-service-heart-icon {
    font-size: 18px;
    margin-left: 13px;
    text-align: right;
  }

  .display-service-location {
    font-size: 15px;
  }

  .display-service-start {
    margin-left: 20px;
  }


  .display-service-button {
    font-size: 10px;
    width: 70px;
    height: 23px;
    display: flex;
    text-align: center;
    margin-left: -15px;
  }
}

@media (min-width:1125px) {
  .display-service-outer-div {
    margin-top: 90px;
  }
}

@media screen and (min-width:279px)and (max-width:350px) {
  .display-service-start {
    font-size: 13px;
    margin-left: -14px;
  }
}

@media screen and (max-width:991px) {

  .filter-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width:768px) and (max-width:1000px) {

  .filter-content p,
  .filter-content span,
  ::placeholder {
    font-size: x-small;
  }

  .showFeaturedService {
    font-weight: 700;
  }
}

@media screen and (max-width:767px) {

  .display-service-sidebar-container2 {
    background-color:var(--white-btn-bg-color);
  }
}

@media screen and (max-width:455px) {

  .display-service-button {
    padding: 5px;
    font-size: 8px;
    width: 48px;
    height: 23px;
    margin-left: -2.6em;
  }

}

.accordion-header {
  border: none !important;
}

.accordion-button::after {
  font-size: 10px;
}

.display-service-sidebar-container3 .row .accordion {
  position: absolute;
  left: -3px;
}

.accordion-button {
  padding: 5px !important;
}

.accordion-body {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.checkbox-button input:checked+span {
  background-color: var(--sura-text-color);
  border-color: var(--sura-text-color);
  color:var(--white-text-color);
}

@media (min-width:1125px) {
  .display-service-outer-div,
  .search-display-des-outer-div {
    margin-top: 150px;
  }

}

@media (max-width:1124px) and (min-width:768px) {

  .display-service-outer-div,
  .search-display-des-outer-div {
    margin-top: 160px;

  }
}

@media (max-width: 767px) {
  .display-service-location {
    font-size: 13px;
  }

  .display-service-sidebar {
    display: none;
  }

  .show-sidebar {
    display: block;
  }

  .menu-icon {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid var(--dark-gray-color);
    border-top: 1px solid var(--dark-gray-color);

  }

}

.min-content {
  font-size: x-small;
}

.clock-icon {
  font-size: 10px;
}

.cart-booking-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 10px;
}

.cart-booking-btn-specialprice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  /* top: 25px; */
}

.cart-booking-btn .cart-icon {
  font-size: 25px;
  color: var(--sura-text-color);
}

@media (max-width:767px) {

  .display-service-outer-div,
  .search-display-des-outer-div {
    margin-top: 120px;
    max-width: unset;
  }

  .dsplay-service-outer {
    margin-top: 145px;
  }
}

.display-service-sidebar-container2::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
}

.display-service-sidebar-container2::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.display-service-sidebar-container2::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.display-service-sidebar-container2::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}

.display-service-sidebar-container3::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
}

.display-service-sidebar-container3::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.display-service-sidebar-container3::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.display-service-sidebar-container3::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}

.hr-transition {
  transition: margin-top 0.5s ease;
}


.bi-suit-heart-fill {
  color: var(--red-color);
  cursor: pointer;
}

@media (max-width:364px) {
  .dsplay-service-outer {
    margin-top: 155px;
  }

}

@media (max-width:996px) and (min-width:421px) {
  .dsplay-service-outer {
    margin-top: 100px !important;
  }
}

@media (max-width:992px) and (min-width:768px) {
  .display-service-pages-outer-container>.container {
    max-width: 850px !important;
  }
}

@media (max-width:768px) {
  .display-service-container-show-data {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}


.sort-by-drawer>.ant-drawer-header {
  background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, #FD6266 88.94%);
  ;

}

.sort-by-drawer>.ant-drawer-header>.ant-drawer-header-title> {
  color: white;
}

.sort-by-drawer>.ant-drawer-header>.ant-drawer-header-title>.ant-drawer-close {
  color: white;
}

.sort-by-drawer>.ant-drawer-header>.ant-drawer-header-title>.ant-drawer-title {
  display: flex;
  justify-content: center;
  color:var(--white-text-color);
  font-size: 1.2rem;
}


.sort-by-drawer .ant-drawer-body {
  background-color: white !important;
  padding: 0px 15px !important;
}

.sort-by-drawer>.ant-drawer-body>.row>.custom-radio>.radio-button-label {
  font-size: 17px;
}

.sort-by-drawer>.ant-drawer-body>.row {
  line-height: 50px;
  height: 50px;
  padding: 0 50px 0 15px;
  display: block;
  border-bottom: 1px solid var(--cashback-container-box-shadow);
}


.filter-drawer>.ant-drawer-header {
  background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, #FD6266 88.94%);
  ;
  padding: 16px 12px;
}

.filter-drawer>.ant-drawer-header>.ant-drawer-header-title>.ant-drawer-close {
  color: white;
}

.filter-drawer>.ant-drawer-header>.ant-drawer-header-title>.ant-drawer-title {
  color: white;
  display: flex;
  justify-content: center;
}

.filter-drawer>.ant-drawer-body {
  padding: 24px 5px;
}

.sort-by-drawer>.ant-drawer-body>.row>.custom-radio>.radio-button-label {
  font-size: 14px;
}

.ant-drawer-left>.ant-drawer-content-wrapper {
  width: 300px !important;
  height: 100% !important;
}

@media (max-width:575px) {
  .display-service-start {
    bottom: 0px;
  }

}

@media (max-width:421px) {
  .display-service-pages-outer-container {
    margin-top: 0px;
  }
}

.sidebar-filter-hr-tag {
  margin-top: 15px;
}

.alterNative-footer-div-20 {
  margin-top: 20%;
}

.alterNative-footer-div-1 {
  margin-top: 1%;
}

@media (max-width:991px) and (min-width:767px) {

  .display-service-pages-outer-container>.display-service-outer-div,
  .search-display-des-outer-div {
    margin-top: 140px;
  }

  .search-display-des-outer-div {
    max-width: 100%;
  }

}

.display-service-filter-service-outer {
  max-height: max-content;
}

.new-display-service-info-outer {
  position: relative;
  top: -2.3px
}

.display-service-heading-new {
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  cursor: pointer !important;
  text-transform: capitalize;
}

/* ---------------------------- new design css ---------------------------------- */

.display-service-section-filter {
  background-color: white;
  outline: 1px solid gainsboro;
  border-radius: 5px;
}

@media (max-width:991px) {
  .share-fav-icon {
    padding-top: 0px;
    top: 5px;
  }

  .display-service-start {
    margin-left: -30px;
  }
}

.display-service-service-section-img {
  height: 80px;
  width: 100px;
  border-radius: 5px;
}

@media (max-width:767px) {
  .display-service-section-outer-filter-col-3 {
    display: none;
  }
}

.display-service-price-outer>span>.hl05eU>.currency-display-d-price {
  color: black !important;
  font-weight: 600 !important;
  font-size: 14px;

}

.display-service-price-outer {
  display: flex;
  gap: 10px;
}

.discount-percent-div {
  font-size: 14px;
  font-weight: bold;
  color: var(--sura-text-color);
  white-space: nowrap;
}

.display-service-price-outer>span>.hl05eU>.yRaY8j {
  margin-left: 0px !important;
  font-size: 12px;
}

@media (max-width:575px) {

  .display-service-price-outer {
    display: flex;
    justify-content: left;
  }

  .cart-booking-btn,
  .cart-booking-btn-specialprice {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 2px;
  }

}

/* ------------------------------------------- new design service info ------------------------ section */

.display-service-service-info-outer-div {
  outline: 1px solid gainsboro;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}


.display-service-service-name-div {
  display: flex;
  justify-content: space-between;
}

.display-service-section-service-name-div {
  font-size: 14px;
  font-weight: bold;
}

.display-service-service-section-service-time>.min-content {
  display: flex;
  font-size: 11px !important;
}

.display-service-service-section-discount-price-outer {
  display: flex;
  gap: 10px;

}

.display-service-special-price>.yRaY8j {
  font-weight: 400;
  font-size: 12px;
  text-decoration: line-through;
}

.display-service-discount-percent>.discount-percent-div {
  font-size: 12px;
}

.display-service-service-section-price-and-butoon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.display-service-service-price-div>.Nx9bqj {

  color: black !important;
  font-weight: 600 !important;
  font-size: 14px;
}

.display-service-service-section-img {
  width: 100%;
  aspect-ratio: 1/1;
  height: 118px;
  border-radius: 5px;
}

.display-service-service-section-bookn-button {
  background-color: var(--sura-text-color);
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 6px !important;
}

.display-service-service-section-bookn-button:hover {
  background-color: var(--sura-text-color) !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 0px 6px !important;
}
.display-service-service-image-div{
  min-height: 118px;
}
@media (max-width:575px) {
  .display-service-service-info-outer-div {
    margin-top: 10px;
  }

  .display-service-service-info-outer-div {
    position: relative;
  }

  .display-service-service-image-div {
    display: flex;
    justify-content: center;
  }


  .display-service-service-btn-action-div>.cart-booking-btn>div>span>i {
    font-size: 18px;
  }
}

.share-fav-icon-display-service {
  position: absolute;
  /* padding-top: 4px; */
  top: 5px;
  right: 10px;
  display: flex;
  align-items: center;
}

.display-service-heart-icon {
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
}

.display-service-sidebar-container1>.row>p {
  margin-bottom: 0.5rem;
}

.radio-button-label {
  font-size: 14px;
}

.radio-button-input {
  height: 11px;
}

.ant-checkbox .ant-checkbox-inner {
  height: 14px;
  width: 14px;
}

@media (max-width:385px) {
  .display-service-service-section-img {
    height: 90px;
  }

  .display-service-section-service-name-div {
    font-size: 12px;
  }

  .display-service-service-section-service-time>.min-content>.clock-icon {
    font-size: 8px;
  }

  .display-service-special-price>.yRaY8j {
    font-size: 11px;
  }

  .display-service-discount-percent>.discount-percent-div {
    font-size: 11px;


  }

  .display-service-service-price-div>.Nx9bqj {
    font-size: 12px;
  }

  .display-service-service-btn-action-div>.cart-booking-btn>div>span>i {
    font-size: 16px;
  }

  .display-service-service-section-bookn-button {
    padding: 0px 4px !important;
  }

  .cart-booking-btn>div>.ant-btn-default {
    height: 28px;
  }

  .display-service-service-section-bookn-button {
    font-size: 11px;
  }

  .display-service-pages-outer-container>.after-category-new-div>.breadcrumb-outer-div>.breadcrumb {
    margin-bottom: -20px !important;
  }

  .display-service-card-div {
    padding: 0rem 0.5rem 0rem 0.5rem !important
  }

  .display-service-service-info-outer-div {
    margin-top: 0px;
  }
  .new-display-service-info-outer{
    top: 10px;
  }
}

.display-service-card-div {
  padding: 0.5rem !important
}

.display-service-service-name-div {
  display: flex;
  justify-content: space-between;
  gap: 7px;
}