.book-now-container {
    overflow-x: hidden;
    padding: 0px 30px 0px 30px;
}
.book-email {
    height: 2.5em;
    max-width: 100%;
    border: 1px solid var(--book-border-color);
    border-radius: 5px;
}
.book-name {
    height: 2.5em;
    max-width: 100%;
    border: 1px solid var(--book-border-color);
    border-radius: 5px;
}

.book-div3-price {
    display: flex;
    justify-content: space-between;
}
.book-div3-price>p{
    font-weight: bold;
}

.book-now-p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0em;
}
.radio-button-label{
    cursor: pointer;
}
.booking-details p {
    margin: 0;
    font-size: 14px
}
.book-now-h5 {
    font-size: 14px;
    font-weight: bold;
}
.book-div3-text {
    font-size: 14.5px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}
.book-div3-small {
    font-size: 10px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
}
.book-now-apply-button {
    background: var(--review-rating-active-btn);
    height: 40px;
    border: var(--review-rating-active-btn);
    color: var(--white-text-color);
    width: 80px;
    font-weight: bold;
}

.accordian-input {
    height: 40px;
    width: 73px;
    border: 1px solid var(--book-border-color);
    margin-left: -5px;
    padding: 18px;
}
.book-now-accordion-body {
    padding: 0px 0px 0px 0px;
    margin-left: 0px;
}
.book-now-accordion-button {
    color: var(--review-rating-active-btn);
    margin: -2px 0px auto;
}
.book-now-accordian-label {
    display: flex;
    justify-content: space-between;
}
.ac-btn .accordion-button::after {
    display: none;
}
.ac-btn button {
    height: 22px;
    margin-top: 10px;
}
.book-now-order {
    text-align: center;
    margin-bottom: 10px;
}
.book-now-order Button {
    background-color: var(--review-rating-active-btn);
    color: var(--white-text-color);
    font-weight: bold;
    height: 40px;
    width: 125px;
    font-size: 17px;
}
.cart-payment-image {
    height: 70px;
    width: 110px !important;
}
.payment-checkbox {
    height: 1rem;
    width: 1 rem;
}

.cart-payment-common-outer-div {
    padding: 10px 15px 15px 15px;
    min-height: 185px;
    background-color: var(--white-btn-bg-color);
    border-radius: 5px;
    box-shadow: 0 2px 9px -2px rgba(50, 71, 92, 0.06), 0 4px 9px 1px rgba(50, 71, 92, 0.04), 0 2px 6px 4px rgba(50, 71, 92, 0.02);
}
.cart-payment-heading {
    font-size: 19px;
    font-weight: 500;
    border-bottom: 1px dotted var(--payout-bottum-border);
}
.cart-payment-content {
    margin: 20px 0px 0px 0px;
}
.payment-details-heading{
    font-weight: 600;
}
.cart-payment-price-label{
    font-size: 16px !important;
}
.payment-term-condition{
    color: black;
}
.payment-checkbox-term-condition{
    display: flex;
    align-items: center;
}
.payment-term-condition-small-tag{
    margin: 5px 5px 0px 5px;
}
.checkout-page-outer{
    margin-top: 110px;
}
.book-now-p{
    white-space: nowrap;
}
.accordion-header {
    border: none !important;
}
.accordion-header-icon {
    font-size: 10px;
}
.accordion-button {
    padding: 5px !important;
}
.accordion-body {
    padding-bottom: 7px !important;
    padding-top: 7px !important;
}
.accordion-button:not(.collapsed) {
    background-color: #fff;
}
.accordion-button:focus {
    box-shadow: none !important;
}

@media (max-width:992px) and (min-width:560px) {
    .cart-payment-common-outer-div{
        margin-top: 20px;
    }
    .checkout-page-outer {
        margin-top: 65px;
    }
}
@media (max-width:560px) {
    .cart-payment-common-outer-div{
        margin-top: 20px;
    }
    .checkout-page-outer {
        margin-top: 95px;
    }
}