.custom-dropdown-menu hr {
  margin: 2px 0;
}

.custom-dropdown-menu {
  cursor: pointer;
  background-color: var(--white-btn-bg-color) !important;
  padding: 15px;
  box-shadow: 0px 1px 10px 1px var(--payout-summary-daily-transaction-inner-box-shadow);
  margin: 10px 5px 0px 5px;
  border-radius: 4px;
}

.user-item-div span {
  color: var(--black-color);
}

.color-theme-pink-icon {
  background: linear-gradient(180deg, #EE0A89 0%, black 100%) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
}

.color-theme-dark-icon {
  background: linear-gradient(180deg, black 0%, #FD6266 100%) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
}

.color-theme-blue-icon {
  background: linear-gradient(180deg, blue 0%, white 100%) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
}


.color-theme-green-icon {
  background: linear-gradient(180deg, #008000 0%, white 100%) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
}

.color-theme-red-icon {
  background: linear-gradient(180deg, #FF0000 0%, white 100%) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
}

.color-theme-light-icon {
  background: linear-gradient(180deg, #EE0A89 0%, white 100%) !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
}

.theme-parent-div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.vendor-order-header .navbar {
  display: flex !important;
  justify-content: space-between;
}

.book-now-container-vendor {
  margin-top: 100px;
}

.user-item-div {
  text-decoration: none;
  padding: 5px;
  display: 'flex';
  padding-left: 8px;
  justify-content: center;
}

.custom-dropdown-item a {
  text-decoration: none !important;

}

.navbar-status-section {
  background: var(--navbar-status-section-bg);
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  color: var(--white-text-color);
  font-weight: bold;
}

.ipad-view-log-out-btn {
  color: var(--white-text-color);
  background: var(--bg-color);
  bottom: 10px;
}

.user-item-div span {
  margin: 10px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-offer {
  background: var(--offer-header-bg-color);
  color: var(--offer-header-font-color);
  font-weight: 400;
  font-family: 'Arial';
}

.navbar {
  display: flex;
  background-color: var(--white-btn-bg-color);
  padding: 0px;
  box-shadow: var(--navbar-box-shadow) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sura-logo {
  height: 53px;
  padding: 3px;
  margin: 5px;
  box-sizing: border-box;
  position: relative;
}

.search {
  display: flex;
  position: relative;
  left: 8%;
}

.near-me-btn {
  border: 1px solid;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background: transparent;
  margin: 0px 0px 0px 15px;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 4px 10px;
}

.near-me-btn span {
  color: var(--category-hover-color) !important;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.signIn {
  font-size: 16px;
  font-weight: 400;
  padding: 3px 6px;
  border: 1px solid var(--category-hover-color);
  border-radius: 5px;
  text-decoration: none;
  color: var(--category-hover-color);
  text-transform: capitalize;
  padding: 4px 6px;
  cursor: pointer;
}

.near-me-btn:hover {
  color: var(--white-text-color) !important;
}

.signIn a:hover,
.signUp a:hover {
  text-decoration: none;
  color: #fff;
}

.signUp {
  font-size: 14px;
  font-weight: 400;
}

.signUp a {
  text-decoration: none;
  color: var(--navber-color);

}


.business-section {
  font-size: 14px;
  font-weight: 400;
}

.business-link {
  cursor: pointer;
  /* color: var(--black-color); */
  font-size: 16px;
  font-weight: 400;
  padding: 4px 6px;
  border: 1px solid var(--category-hover-color);
  border-radius: 5px;
  text-transform: capitalize;
}

.business-link:hover {
  cursor: pointer;
  font-weight: 400;
  padding: 3.5px 6px;
  border: 1px solid var(--category-hover-color);
  background-color: var(--category-hover-color);
  color: #fff;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
}

.is-active-dropdown {
  color: var(--sura-text-color);

}

.business-section .dropdown-menu li a {
  text-decoration: none;
}

.header-cart-anchor,
.header-cart-anchor:hover {
  color: var(--white-text-color);
  display: flex;
  align-items: center;
  position: relative;
}

.header-cart-anchor1,
.header-cart-anchor1:hover {
  display: flex;
  align-items: center;
  position: relative;
}

.navbar-sup {
  font-weight: bold;
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 14%;
  left: 100%;
  height: 15px;
  width: 15px;
  transform: translate(-50%, -50%);
  background: var(--bg-color);
  color: var(--white-text-color);
  border-radius: 50%;
}

.header-cart {
  font-size: 20px;
  color: var(--black-color);
}

.navbar-flag-icon {
  height: 15px;
  width: 20px;
}

.dropdown-item:active {
  color: var(--black-color);
  background: var(--white-btn-bg-color)
}

.menubar {
  display: none;
}

.logo-section {
  display: flex;
  gap: 15px;
}

.logo-section .menubar {
  position: relative;
  top: 24px;
  left: 10px;
}

.iPad_barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: black !important;
  position: relative;
}

.ipad-user-name-div {
  color: var(--white-text-color);
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.ipad-localstorage-profile {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.iPad_barsBtn:after,
.iPad_barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: black !important;

}

.iPad_barsBtn:after {
  top: auto;
  bottom: -6px;
}

.afterlogin-drawer {
  background: var(--bg-color) !important;

}

.iPad-view-active-link-div {
  background-color: var(--white-btn-bg-color);
  border-radius: 5px;
}


.iPad-view-active-link-linktag,
.iPad-view-active-link-linktag:hover {
  color: var(--hotpink-border-color) !important;

}

.navbar-sup {
  font-weight: bold;
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 14%;
  left: 100%;
  height: 15px;
  width: 15px;
  transform: translate(-50%, -50%);
  background-color: var(--white-btn-bg-color);
  color: var(--bg-color);
  border-radius: 50%;
}

.search-field-mobileview {
  display: none;
}

.near-me-icon-div {
  display: none;
}

.sidebar-near-me {
  display: none;
}

.ipad-navbar-hr-tag {
  margin: 0.3rem 0 !important;
  color: inherit;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  max-width: 60% !important;
  width: 60% !important;
}

.navbar-signin-dropdow-toggle {

  color: var(--black-color) !important;
  text-align: left;
}

.issignup {
  font: normal normal normal 14px/1 FontAwesome !important;
}

.not-open-sign-sub-menu {
  display: none;
}


.signup-sub-menu-outer-div {
  margin: 0px 0px 0px 10px;
}

.signup-menu-option {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--black-color);
}

.ipad-navbar-outer-content-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.ipad-navbar-sign-link,
.ipad-navbar-sign-link,
.ipad-navbar-near-me-before-login {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--black-color);
}


.navbar-category .card {
  border-radius: 0px;
}

.mobile-view-close-icon {
  right: 10px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
}

.ipad-navbar-profilephoto-div {
  text-align: center;
}

.ipad-user-name-div {
  color: var(--white-text-color);
}

.ipad-navbar-near-me,
.ipad-navbar-near-me:hover {
  color: var(--white-text-color);
}



.ipad-navbar-near-me {
  padding: 10px;
}


.mobile-view-logoutbtn-div {
  display: flex;
  justify-content: center;
}

.ipad-navbar-near-me:hover {
  color: var(--white-text-color);
}

.navbar-search {
  display: none;
}


.basic-box-shadow {
  box-shadow: 0 5px 5px 5px var(--mdl-btn-color);
}

.navbar-category-hr-tag {
  margin: 0px 0px !important;
  color: #D9D9D9;
}

.user_header_profilePhoto {
  height: 35px;
}

.near-me-text {
  display: none;
}

@media (min-width:1125px) {

  .show-inner-header-web-inner-navbar-inner-outer-div {
    position: fixed;
    z-index: 3;
  }

  .after-deletion-request {
    top: 55px;
  }

  .show-inner-header-web-inner-navbar-inner-outer-div {
    z-index: 3;
    top: 0;
    background-color: var(--white-btn-bg-color);
    position: fixed;
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--navbar-box-shadow) 0px 8px 24px;
    padding: 2px 10px 2px 10px;
    margin: 0px 10px 0px 0px;
    text-transform: capitalize;
  }


  .web-navbar-logo-image-tag {
    height: 53px;
    padding: 3px;
    margin: 5px;
    box-sizing: border-box;
  }

  .navbar-actions {
    margin: 15px 5px;
    justify-content: space-between;
  }



  .navbar-sup {
    font-weight: bold;
    display: inline-flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 19%;
    left: 100%;
    height: 15px;
    width: 15px;
    transform: translate(-50%, -50%);
    background: var(--bg-color);
    color: var(--white-text-color);
    border-radius: 50%;
  }

  .web-navbar-profile-name {
    float: right;
  }


  .localstorage-profile {
    vertical-align: middle;
    border-radius: 50%;
  }

  .vendor_Header_UserName {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 16px;
  }

  .navbar-search .fa-angle-down,
  .web-navbar-profile-name .fa-angle-down {
    margin: 0;
    font-size: 10px;
  }
}

@media (max-width:1124px) {
  .menubar {
    display: block;
  }

  .navbar {
    justify-content: space-between;
  }

  .near-me-button {
    display: none;
  }

  .near-me-text {
    display: block;
  }
}

@media (max-width:1060px) and (min-width:768px) {
  .search-field-mobileview {
    display: none;
  }

  .ant-drawer-right .ant-drawer-content-wrapper {
    max-width: 25vw !important;
    width: 50% !important;
  }

  .iPad_barsMenu {
    display: inline-block !important;
    color: black !important;
  }

  .iPad_barsMenu {
    padding: 15px 0px 0px 0px;
    border: none;
  }

  .iPad_barsMenu {
    margin: 0px 10px 0px 0px;
  }

  .iPad_barsMenu {
    float: right;
    height: 23px;
    display: none;
    background: none !important;
  }

  .menubar {
    display: block;
  }

  .signIn {
    display: none;
  }

  .signUp {
    display: none;
  }

  .business-section {
    display: none;
  }

  .profile {
    display: none;
  }

  .ipad-navbar-sign-link:hover {
    color: var(--sura-text-color);
  }

  .near-me-button {
    display: none;
  }

  .navbar {
    justify-content: space-between;
  }
}

@media (max-width:767px) {
  .vendor-menubar {
    top: 24px !important;
  }

  .ant-drawer-right .ant-drawer-content-wrapper {
    max-width: 35vw !important;
    width: 50% !important;
  }

  .sidebar-near-me {
    display: block;
  }

  .profile {
    display: none;
  }

  .header-offer {
    display: none;
  }

  .near-me-button {
    display: none;
  }

  .near-me-icon-div {
    display: block;
    margin: 5px 15px;
  }

  .near-me-icon {
    font-size: 20px;
  }

  .signIn {
    display: none;
  }

  .signUp {
    display: none;
  }

  .business-section {
    display: none;
  }

  .search-box-section {
    position: relative;
    display: flex;
    height: 35px;
    border-radius: 6px;
    margin: 0px 5px;
    padding: 0 7px;
  }

  .isTempSearchBoxSeciont {
    right: 15%
  }

  .header {
    background-color: var(--white-btn-bg-color);
    padding: 0px;
    box-shadow: var(--navbar-box-shadow) 0px 8px 24px;
  }

  .navbar {
    box-shadow: 0px 0px 0px;
    justify-content: space-between;
  }

  .menubar {
    display: block;
  }

  .iPad_barsMenu {
    display: inline-block;
    float: right;
    height: 19px;
    font-size: 20px;
    display: block !important;
    background: none !important;
    padding: 9px 0px 0px 0px;
    border: none;
    margin: 0px 10px 0px 0px;
  }

  .ipad-navbar-sign-link,
  .ipad-navbar-sign-link,
  .ipad-navbar-near-me-before-login {
    font-size: 16px;
    color: var(--black-color);
    margin: 10px 0px;
  }

  .anticon-shopping-cart {
    font-weight: 400;
    color: var(--black-color);
    font-size: 24px;
  }

}


@media (max-width:605px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    max-width: 45vw !important;
    width: 50% !important;
  }

  .active-search-logo {
    display: none;
  }

  .active-search-action {
    display: none;
  }

  .search {
    display: none;
  }

  .navbar-search {
    display: block;
  }

  .active-search-search-outer-div {
    width: 100%;
  }

  .active-search-search-div {
    width: 100%;
  }
}

@media (max-width:445px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    max-width: 60% !important;
    width: 60% !important;

  }
}

@media (max-width:400px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    max-width: 100% !important;
    width: 100% !important;

  }

}

.after-deletion-request-inner-header {
  margin-top: 55px;
}

.after-deletion-request-navbar {
  margin-top: 55px;
}

.notification-icon {
  font-size: 20px;
  cursor: pointer;
  margin: 2px 0px 0px 0px;
}

@media (max-width:1124px) and (min-width:768px) {
  .notification-icon {
    font-size: 20px;
    cursor: pointer;
  }
}

.menusidebar-role-inner-width {
  display: none;
}

@media (max-width:1125px) {
  .menusidebar-role-inner-width {
    display: block;
  }
}

@media (max-width:373px) {
  .after-deletion-request-navbar {
    margin-top: 80px;
  }
}

.mobile-log-img-tag {
  display: none;
}

.mobile-logo-img-tag {
  display: none;
}

.mobile-view-inner-search-fild-outer-div {
  display: none;
}

@media (max-width:421px) {
  .home-notification>.notification-container>.navbar-sup-notification {
    top: 70% !important;
  }

  .mobile-view-inner-search-fild-outer-div {
    display: flex;
    align-items: baseline;
  }

  .user-notification .navbar-sup-notification {
    position: absolute;
    top: 70%;
  }

  .navbar-search {
    display: none;
  }

  .mobile-logo-img-tag {
    display: block;
  }

  .logo-section .menubar {
    display: none;
  }

  .navbar-search-field-new .actions .notification {
    display: none !important;
  }

  .sura-log-web {
    display: none;
  }

  .search-box-section {
    margin: 5px 5px;
    right: unset;
  }

  .mobile-view-inner-search-fild-outer-div {
    padding: 0px 15px 0px 5px;
  }
}

.nvabar-profile {
  align-items: center;
}

.signIn>a>i,
.signUp>a>i,
.business-link>i {
  font-size: 22px;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: baseline;
}

.navbar-search-field-new {
  display: flex;
  align-items: baseline;
  gap: 20px;
  align-items: center;
}

.navbar-search-field-new {
  height: 64px;
}

.language-hamburger {
  display: flex;
  align-items: normal;
  gap: 10px;
}

.business-section .dropdown-menu {
  --bs-dropdown-min-width: 8.7rem !important;
}

.vendor-menubar {

  position: relative;
  top: 36px;
  left: 10px;
}

@media (min-width:768px) and (max-width:1060px) {

  .navbar .logo-section .menubar {
    top: 20px !important;
  }

  .vendor-menubar {
    top: 18px;
  }
}

@media (min-width:1061px) and (max-width:1125px) {
  .navbar .logo-section .menubar {
    top: 35px !important;
  }
}

@media (min-width:992px) {
  .vendor-menubar {
    display: none;
  }
}



/* ------------------------------------------- city modal design ----------------------------- */
.navbar-city-section,
.language-outer-div,
.language {
  display: flex;
  align-items: center;
}

.city-section-inner {
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: rgb(26, 26, 26);
  line-height: 19px;
  transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}