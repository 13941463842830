.search-box-section {
  max-width: 500px;
  width: 100%;
  position: relative;
  display: flex;
  height: 35px;

  border: 1px solid rgba(217, 217, 217, 0.5);
  border-radius: 6px;
  margin: 0px 5px;
  padding: 0 7px;
  background: white;
  border-radius: 7px !important;

}
.outer-search-feild-booking-buisiness-inner .search-box-section{
  border: 1px solid hotpink;
}

.search-input {
  width: calc(100% - 35px);
  font-size: 16px;
  padding: 5px;
  color: #6c6c6c;
  border: none;
  transition: all .4s;
  font-family: Arial;
}

input.search-input::placeholder,
.search-input ::placeholder {

  font: 0.75rem/4 sans-serif !important;
  color: var(--placeholder-color) !important;
}

.search-input:focus {
  border: none;
  outline: none;
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 0px 9px;
  border: none;
  color: #6c6c6c;
  transition: all .4s;
  width: 35px;
  border-left: 2px solid #eaeaea;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: var(--black-color);
}

.search-btn:focus {
  outline: none;
  color: var(--black-color);
}


.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 36px;
  background-color: var(--search-box-section-bg);
  line-height: 55px;
  padding-right: 55px;
  border-radius: 5px;
  transition: all 0.5s ease;
}


.wrap .fa {
  color: var(--black-color);
  position: absolute;
  right: 17px;
  top: 6px;
  font-size: 22px;
  cursor: pointer;
}

.wrap.active {
  width: 250px;
  padding-left: 25px;
  transition: all 0.5s ease;
}


.search-icon {
  display: none;
}

@media (max-width:605px) {
  .isTempSearchBoxSeciont {
    display: none !important;
  }

  .search-icon {
    display: block;
  }

  ._3pj9cs .YYZBZw._3rvD0w {
    transform: translateY(0);
  }

  .search-box-section-active {
    max-width: 500px;
    width: 100%;
    position: relative;
    display: flex;
    height: 35px;
    border-radius: 6px;
    margin: 0px 5px;
    padding: 0 7px;
    border: 1px solid rgba(217, 217, 217, 0.5);
  }

  .deactiveSearch {
    display: none;
  }

  .activeSearch {
    display: block;
  }

  .search-form {
    display: flex;
  }

  ._1je_xh {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .search-icon-style {
    font-size: 18px;
  }

}

input:-internal-autofill-selected {
  background-color: var(--red-color);
}

input:-internal-autofill-selected {
  color: var(--red-color);
}