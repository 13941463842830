.contact_field .form-control,
select::placeholder {
    letter-spacing: 1px;
    color: #76787a;
}
.service-trip-plan{
    color: black !important;
}
.trip-section-service-label {
    font-size: 14px !important;
    letter-spacing: 1px;
    color: #76787a;
}
.country-code-trip-plan >.ant-select-selector{
    border: none !important;
    padding: 0px !important;
}
.country-code-trip-plan > .ant-select-selector:focus-visible {
    border: none !important;
    padding: 0px !important;
}
.trip-plan-custom-select-outer-div .form-control{
    padding: .375rem .75rem !important;
}

.trip-plan-custom-select-outer-div > div> div> .ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
    border: none !important;
}
.trip-section-service-label {
    white-space: nowrap; /* Prevents wrapping to new lines */
    overflow: hidden; /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds ellipsis when the text overflows */
    display: inline-block; /* Ensure it behaves like text in a line */
    max-width: 100%; /* Set a max width to trigger ellipsis */
  }
  
.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: white !important;
    box-shadow: none !important;
}
.trip-plan-arrow >.bi{
    font-size: 19px;
}



.trip-plan-date-picker-plan>.ant-picker {
    width: 100%;
    border-radius: 0px !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-bottom: 1px solid #ccc !important;

}

.ant-picker-input:focus {
    outline: none !important;
    border: none !important;
}

.trip-section-service-hr {
    border-radius: 0px;
    border: none;
    color: #495057;
    border-bottom: 1px solid #ccc;
    width: 100%;
}


.trip-plan-image {
    position: absolute;
    top: 28%;
    height: 340px;
    width: 40%;
    border-radius: 25px 0 0 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    right: 0;
}

.trip-plan-image h4 {
    letter-spacing: 1px;
    padding-bottom: 15px;
}

@media (max-width:922px) {
    .trip-plan-image {
        background-color: unset;
        right: 1px;
        padding: 40px;
        border-radius: 25px 0 0 25px;
        background-image: unset !important;
    }
}

@media (max-width:991px) {
    .contact_form_inner {
        padding: 20px;
    }
}