.team-meber-service-list-outer-div{
    border-radius: 4px;
    box-shadow: var(--box-shado-all-dashboard);
    max-height: 50vh;
    overflow: auto;
}
@media (max-width:768px) {
    .team-meber-service-list-outer-div{
        border-radius: 4px;
        box-shadow:var(--box-shado-all-dashboard);
        max-height: auto;
        overflow: hidden;
    }
    
}
.team-member-service-name {
  padding: 10px;
}
.team-member-service-inner-div {
  margin: 0px 20px;
}
.team-meber-service-list-outer-div::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.team-meber-service-list-outer-div::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color) !important;
  border-radius: 1em !important;
}
.team-meber-service-list-outer-div::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color) !important;
}
.team-meber-service-list-outer-div::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--warning-container-bg) !important;
}
.OpeningHoursPolicies_DayWise_Time>.row>.opening-hourse-to-div{
  text-align: center;
}
@media (min-width:575px) {
  .team-member-services-outer-div {
    margin-top: 30px;
  }
}
@media (max-width:574px) {
  .team-member-services-outer-div {
    margin-top: 20px;
  }
}