.cancel-deletion-inner-div {
    background-color: var(--white-btn-bg-color);
    border-radius: 5px;
    margin-bottom: 20px;
}
.cancel-deletion-content {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px 30px 8px 30px;
}
.cancel-deletion-content-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.cancel-deletion-content-cance-button {
    margin-left: 2vw;
}
.cancel-deletion-button {
    text-align: center;
    font-weight: bold;
    text-align: center;
    background: var(--bg-color);
    color: var(--white-text-color) !important;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1.399999976158142px;
    text-align: center;
}