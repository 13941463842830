  #select-item {
    border: 1px solid var(--warning-container-border);
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
  }
  
  #select-item input[type="text"] {
    padding: 0px 5px;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #D9D9D9;
  }
  
  #select-item ul {
    list-style-type: none;
    padding: 0;
    max-height: 110px;
    overflow-y: auto;
    overflow-x: hidden
  }

  .edit-team-member-checkbox input[type="checkbox"]:checked {
    border-color:var(--sura-text-color)!important;
    background-color:var(--sura-text-color)!important;
  }
  
.edit-team-member-checkbox input{
width:30px;
}
.select-header {
  cursor: pointer;
  padding:7px;
  border: 1px solid var(--warning-container-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}

.select-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color:var(--inner-background-db);
  border: 1px solid var(--search-input-border-color);
  z-index: 1;
  box-shadow: 0px 4px 8px var(--select-item-box-shadow);
  display: none;
}

.select-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.select-item ul li {
  padding: 10px;
  cursor: pointer;
}

.select-item ul li:hover {
  background-color: var(--warning-container-bg);
}

.fa-angle-down {
  font-size: 10px;
}