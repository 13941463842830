@import "../../../assets/styles/style.css";

:root {
    --font-base: "Lato";
    --fs-700: 2.986rem;
    --fs-600: 2.488rem;
    --fs-500: 2.074rem;
    --fs-400: 1.728rem;
    --fs-300: 1.44rem;
    --fs-200: 1.2rem;
    --fs-100: 1rem;
    --fs-50: 0.833rem;
    --white: #ffffff;
    --grey-50: #f8fafc;
    --grey-100: #f1f5f9;
    --grey-200: #e2e8f0;
    --grey-300: #cbd5e1;
    --grey-400: #94a3b8;
    --grey-500: #64748b;
    --grey-600: #475569;
    --grey-700: #334155;
    --grey-800: #1e293b <div className="modal-footer" > <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button> <button type="button" className="btn btn-primary" >Save changes</button> </div>;
    --grey-900: #0f172a;
    --grey-950: #020617;
    --primary: #006ed8;
    --primary-50: hsl(209, 100%, 52%);
    --text: var(--grey-500);
    --text-alt: var(--grey-900);
    --background: var(--grey-200);
    --background-alt: var(--grey-100);
    --background-shade: var(--grey-100);
}

.ant-picker-outlined {
    border-radius: 6px;
}

@media (prefers-color-scheme: dark) {
    :root {
        --text: var(--grey-500);
        --text-alt: var(--grey-100);
        --background: var(--grey-900);
        --background-alt: var(--grey-800);
        --background-shade: var(--grey-700);
    }
}

h1,
h2,
h3,
h5,
h6 {
    line-height: 1.15;
    font-weight: 700;
}

a {
    text-decoration: none;
}

a:hover {
    color: var(--sura-text-color);
    text-decoration: underline;
}

.branch-avatar {
    vertical-align: middle;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.hideInput {
    display: none;
}

.multiImage-div {
    overflow-x: auto;
}

.lable-width {
    width: 100%;
}

.multiImage-div-inner {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.selected-image {
    width: 70px;
    height: 70px;
    border: 1px solid var(--search-input-border-color);
    border-radius: 5px;
}

.multiImage-div-inner-main {
    position: relative;
    margin-right: 10px;
    margin-top: 5px;
}

.form-row-branch .edit-profile>.sc-aXZVg {
    opacity: 0;
    display: block;
    top: 20px;
    left: 15px;
    align-items: center;
    min-width: unset;
    max-width: unset;
    border: 2px dashed rgb(6, 88, 194);
    padding: 0px;
    border-radius: 5px;
    cursor: pointer;
    height: 43px;
    width: 43px;
    border-radius: 50%;
}

.branch-circle-background {
    margin-left: 50px;
    margin-top: 33px;
    width: 20px;
    height: 20px;
    background-color: var(--white-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-branch-image-section>label {
    position: relative;
    display: flex;
    align-items: center;
    height: 135px !important;
    border: dashed 2px #0658c2;
    padding: 8px 16px 8px 8px;
    cursor: pointer;
    flex-grow: 0;
    min-width: 135px !important;
    max-width: 135px !important;
    border-radius: 50%;
    opacity: 0;
}

.edit-branch-header {
    border-bottom: 1px solid var(--warning-container-border);
}

.title {
    margin-top: unset;
    margin-bottom: 1rem;
    text-align: center;
}

.edit-branch-plus-icon i {
    height: 18px;
    color: var(--sura-text-color);
}

.form-wrapper {
    width: 100%;
    padding: clamp(1rem, 5vw, 2rem);
    margin: auto;
    background-color: var(--background-alt);
    border-radius: 0.25rem;
}

.form-wrapper .title {
    color: var(--text-alt)
}

.form-row-branch {
    margin-bottom: -15px;
}

.form-group {
    flex-grow: 1;
    flex-shrink: 1;
}

.select-margin {
    padding: 2px;
    font-size: 15px;
    width: 100%;
    height: 2.4em;
    border-radius: 6px;
    appearance: auto;
}

.phone-feild-div {
    margin-top: 10px;
}

.form-submit-branch {
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    background: var(--bg-color);
    color: var(--white);
    height: 41px;
    width: 120px;
}

.form-cancel-branch,
.form-cancel-branch:hover {
    margin: 0px 4px;
    border-radius: 0.25rem;
    cursor: pointer;
    height: 41px;
    width: 120px;
    border: 1px solid var(--warning-container-border)!important;
}

.form-cancel {
    margin-right: 10px;
    background:var(--white-color);
    color: var(--black-color);
    border: 1px solid var(--warning-container-border);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 41px;
    width: 120px;
    padding-top: 8px;
}

.form-submit:hover {
    background-color: var(--primary-50);
}

.custom-estric {
    margin-left: 2px;
    color:var(--red-color)!important;
}

.form-footer {
    margin-top: 2rem;
    color: var(--text)
}

.ant-modal-content {
    width: 85% !important;
    margin-left: 3em;
}

@media screen and (min-width: 40rem) {
    .form-wrapper {
        max-width: 50ch;
    }

    .form-row {
        display: flex;
    }

    .form-row--inline {
        display: inline-flex;
    }

    .form-row>.form-group {
        margin-right: .5rem;
        margin-left: 0.5rem;
    }

    .form-row>.form-group:first-child {
        margin-left: unset;
    }

    .form-row>.form-group:last-child {
        margin-right: unset;
    }
}

#lang {
    width: 470px;
    border-color: var(--lang-border-color);
    border-radius: 10px;
}

.btn-close {
    margin-left: 350px;
    margin-top: -20px;
}

.error {
    padding-bottom: 1px;
    margin-bottom: 1px;
}

@media screen and (max-width:820px) {
    #first-name {
        width: 75vw;
    }

    #last-name {
        width: 75vw;
    }

    #email {
        width: 75vw;
    }

    #lang {
        width: 75vw;
        ;
    }
    }


@media screen and (max-width:549px) {
    #lang {
        width: 78vw;
        ;
    }
}

.form-input:focus {
    border-color: var(--warning-container-border) !important;
}

.edit-branch-postal-code-outer-div {
    margin-top: -10px;
}

.edit-branch-cross-icon {
    position: absolute;
    top: 17%;
    right: 16%;
    transform: translate(50%, -50%);
    background-color: white;
    border: 2px solid #D9D9D9;
    border-radius: 25%;
    padding: 3px;
    transition: 50ms;

}

@media screen and (max-width: 642px) {
    .ant-modal-content {
        width: 100%;
        margin: 3em auto !important;
    }
}