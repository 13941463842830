.delete-modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78%;
    width: 30%!important;
}
.delete-container {
    margin-top: -43px;
}
#deleteTeamMember .modal-header{
    border-bottom: none!important;
}
.delete-modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: none;
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.upload-file-button-outer-div {
    display: flex;
    justify-content: center;
 }
.upload-file-cancel-button,
.upload-file-upload-button {
    width: 100%;
    margin-right: 10px;
}
.upload-modal-cancel-button,
.upload-modal-upload-button {
    width: 100%;
}
@media (max-width: 900px) {
    .delete-modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        width: 78% !important;
        margin: 3em auto !important;
    }
}
@media (max-width: 576px) {
    .delete-modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        width: 78% !important;
        margin: 3em auto !important;
    }
}