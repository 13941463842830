@media (min-width: 250px) {
  .referal-back-image{
    margin-top: -2px !important;
  }
}

@media (min-width: 400px) {
  .referal-back-image{
    margin-top: -50px !important;
  }
}

@media (min-width: 576px) {
  .referal-back-image{
    margin-top: -100px !important;
  }
}

@media (min-width: 768px) {
  .referal-back-image{
    margin-top: -145px !important;
  }
}
@media (min-width: 992px) {
  .referal-back-image{
    margin-top: -210px !important;
  }
}
@media (min-width: 1200px) {
  .referal-back-image{
    margin-top: -245px !important;
  }
}
@media (min-width: 1400px) {
  .referal-back-image{
    margin-top: -310px !important;
  }
}
.referal-back-image{
  margin-top: -320px;
  width: -webkit-fill-available;
}
.section {
  text-align: center;
}

.steps {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.step {
  margin: 0 20px;
}

.step .icon {
  width: 50px;
  height: 50px;
  background-color: var(--step-icon-bg);;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.step span {
  font-size: 14px;
  color: #666;
}

.description p {
  margin: 5px 0;
  z-index: 1;
}

.referral-code {
  background-color: var(--referral-code-bg);;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.share-buttons {
  display: flex;
  justify-content: center;
  z-index: 1;
}

.share-button { 
  margin: 0 5px;
  align-items: center;
}

.share-button img {
  width: 20px;
  height: 20px;
}
.description{
  position: relative;
}

@media (min-width: 250px) {
  .referal-back-image{
    margin-top: -2px !important;
  }
}

@media (min-width: 400px) {
  .referal-back-image{
    margin-top: -50px !important;
  }
}

@media (min-width: 576px) {
  .referal-back-image{
    margin-top: -100px !important;
  }
}

@media (min-width: 768px) {
  .referal-back-image{
    margin-top: -145px !important;
  }
}
@media (min-width: 992px) {
  .referal-back-image{
    margin-top: -210px !important;
  }
}
@media (min-width: 1200px) {
  .referal-back-image{
    margin-top: -245px !important;
  }
}
@media (min-width: 1400px) {
  .referal-back-image{
    margin-top: -310px !important;
  }
}