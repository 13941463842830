.city-modal>.modal-dialog {
    top: 40% !important;
}

.city-modal>.city-modal-outer {
    --bs-modal-width: 82%;
}

.search-field-parent {
    display: flex;
    flex-flow: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    opacity: 1;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 2px;
}


.city-modal-autocomplete {
    border: none;
    width: 100%;
    /* height: 45px; */
    border: none;
    font-size: 14px;
    text-indent: 5px;
    outline: none;
}

.detect-location-section {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.detect-location-parent {
    display: flex;
    cursor: pointer;
    gap: 10px;
    color: rgb(220, 53, 88);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
}



.city-modal-autocomplete {
    position: relative;
    /* Positioning context for the pseudo-element */
    width: 100%;
    /* Ensure the wrapper takes full width */
    height: 40px;
    /* Adjust height as needed */
    border: 1px solid #ccc;
    /* Optional: border style */
    border-radius: 0px;
    /* Optional: rounded corners */
    display: flex;
    /* Use flexbox for alignment */
    align-items: center;
    /* Center items vertically */
}

.city-modal-autocomplete input {
    /* Targeting the input inside the wrapper */
    padding-left: 50px;
    /* Space for the icon */
    width: 100%;
    /* Adjust width considering the icon space */
    height: 100%;
    /* Match the height */
    border: none;
    /* Remove default border */
    border-radius: 4px;
    /* Match the wrapper's border radius */
    font-size: 16px;
}

.city-modal-autocomplete::before {
    content: '🔍';
    /* Unicode for the magnifying glass emoji (search icon) */
    position: absolute;
    /* Absolute positioning for the icon */
    left: 10px;
    /* Space from the left */
    top: 50%;
    /* Center the icon vertically */
    transform: translateY(-50%);
    /* Adjust for vertical alignment */
    font-size: 18px;
    /* Adjust size as needed */
    color: #999;
    /* Icon color */
    pointer-events: none;
    /* Prevent pointer events on the icon */
}

.city-modal-city-section {
    padding: 8px 0px 0px 0px;
    display: flex;
    max-width: 100%;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 0 0 auto;
}


/* -------------------------------------------- popular cities section------------------------ */
.popular-city-heading {
    display: flex;
    justify-content: center;
    font-size: 18px;
    text-transform: none;
    color: rgb(51, 51, 51);
    cursor: default;
    font-weight: bold;
    margin-top: 5px;
}

.gZrltM {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 8px 0px 8px 0px;
}

.KUowN {
    display: flex;
    cursor: pointer;
    margin: 8px 12px;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.hkqhSR {
    display: flex;
    cursor: pointer;
    margin: 0px;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: relative;
}

.gvzyfS {
    /* width: 70px; */
    height: 70px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-bottom: 5px;
    -webkit-box-pack: center;
    justify-content: center;
}

.izPSOY {
    height: 100%;
    max-width: 100%;
}

.dizuyr {
    color: rgb(102, 102, 102);
    font-size: 14px;
    max-width: 110px;
    word-break: break-word;
    text-align: center;
}

/* --------------------------- view all city section-------------------- */
.view-all-city-section {
    padding: 8px 0px 0px 0px;
    display: flex;
    max-width: 100%;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 0 0 auto;
}

.jhbnqL {
    font-size: 14px;
    width: fit-content;
    text-transform: none;
    color: rgb(220, 53, 88);
    cursor: pointer;
}

/* ---------------------------------------- view all cities------------------------- */
.view-all-city-list-section {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden
}

.other-cities-option {
    font-size: 14px;
    width: fit-content;
    text-transform: none;
    color: rgb(51, 51, 51);
    cursor: default;
}

.city-modal-city-name{
    color: rgb(153, 153, 153);
    font-size: 12px;
    cursor: pointer;
    text-align: left;
    line-height: 18px;
    text-decoration: none;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.city-modal-city-name:hover {
    color: rgb(51, 51, 51);
}
.disable-view-all-cities{
    pointer-events: none;
    opacity: 0.5;
    font-size: 14px;
    width: fit-content;
    text-transform: none;
    color: rgb(220, 53, 88);
}

@media (max-width:350px) {
    .city-modal>.modal-dialog {
        top: 50% !important;
    }
}