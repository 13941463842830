  .shop-card-heart-shop {
    color: var(--red-color);
    font-size: 25px;
  }

  .shop-card-share-shop {
    cursor: pointer;
    color: var(--review-rating-active-btn);
    font-size: 20px;
  }

  .shop-cart-dd {
    text-transform: capitalize;
    padding: 10px;
    width: 100%;
    background:var(--white-btn-bg-color);
    border-radius: 5px;
    outline: 1px solid gainsboro;
  }

  .image-shop-outer-div {
    padding: 10px;
  }

  .user-img-review {
    display: flex;
    align-items: flex-start;
  }

  .user-img-review img {
    height: 100px;
    width: 100px;
    border-radius: 5px;
  }

  .shop-icons {
    font-size: 24px;
    margin-top: auto;
    display: flex;
    margin-top: 5px;
    /* gap: 20px; */
    justify-content: space-between;
    width: 100%;
  }

  .shop-card-address {
    font-size: 12px;
  }

  .user-text-review {
    position: relative;
    margin-top: 7px;
    width: 100%;
  }

  .user-text-review>p {
    margin-bottom: 0px;
    padding: 0px;
  }


  @media screen and (max-width: 475px) {
    .view-button-shop {
      height: 23px;
      width: 50px;
      padding: 0px;
    }

    .shop-card-heart-shop {
      color: var(--red-color);
      font-size: 20px;
    }

    .shop-card-share-shop {
      cursor: pointer;
      color: var(--review-rating-active-btn);
      font-size: 20px;
    }

    .shop-cart-dd {
      box-shadow: 0px 1px 10px 1px var(--payout-summary-daily-transaction-inner-box-shadow);
      text-transform: capitalize;
      border-radius: 4px;
      padding: 6px;
    }

    .user-img-review img {
      height: 95px;
      width: 95px;
      border-radius: 5px;
    }

    .shop-icons {
      padding-top: 31px;
      font-size: 16px;
      margin-top: auto;
      display: flex;
      justify-content: space-between;
    }

    .shop-card-address {
      font-size: 12px;
    }

    .user-text-review {
      position: relative;
      font-size: 12px;
    }

    .user-text-review>p {
      margin-right: 10px;
    }

    .shop-cart-dd {
      box-shadow: 0px 1px 10px 1px var(--payout-summary-daily-transaction-inner-box-shadow);
      text-transform: capitalize;
      padding: 10px;
      border-radius: 4px;
      min-height: 120px;
    }
  }


  @media screen and (max-width: 384px) {
    .shop-cart-dd {
      box-shadow: 0px 1px 10px 1px var(--payout-summary-daily-transaction-inner-box-shadow);
      text-transform: capitalize;
      padding: 10px;
      border-radius: 4px;
      padding: 10px;
    }

    .shop-icons {
      padding-top: 0px;
      font-size: 16px;
      margin-top: auto;
      display: flex;
      justify-content: space-between;
    }

    .shop-card-share-shop {
      cursor: pointer;
      color: var(--review-rating-active-btn);
      font-size: 16px;
    }
  }

  .reviewShop-rating-modal .ant-modal-content {
    box-shadow: var(--reviewShop-rating-modal-box-shadow) 0px 2px 2px 0px !important;
  }

  .ant-modal-root .ant-modal-mask {
    position: fixed;
    inset: 0;
    z-index: 1000;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
  }

  .shop-review-addres-rating p {
    margin-bottom: 0rem !important;
    margin-top: 0.5rem;
  }

  .review-shop-rating-mobile {
    display: none;
  }

  @media (max-width:575px) {
    .review-shop-rating-web {
      display: none !important;
    }

    .review-shop-rating-mobile {
      display: block;
    }

    .image-shop-outer-div {
      padding: 10px 0px 10px 0px;
    }

    .user-img-review {
      display: block;
      justify-content: center;
    }

    .image-shop-outer-div {
      display: flex;
      justify-content: center;
    }

    .shop-icons {
      position: initial !important;
      padding-top: 0px !important;
    }
    .shop-icons>span>i{
      font-size: 20px;
    } 

    .display-service-heading {
      display: flex;
      justify-content: center;
    }

    .shop-review-addres-rating {
      display: flex;
      justify-content: center;
    }

    .user-text-review {
      margin-top: 0px;
      padding: 0px;
    }

    .shop-review-addres-rating p {
      margin-bottom: 0rem !important;
      margin-top: 0rem;
    }
  }

  .ant-picker-ok button {
    background: var(--sura-text-color);
    color: white;
    font-weight: bold;
  }

  .ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled,
  .ant-picker-time-panel-column-0 .ant-picker-time-panel-cell-disabled+.ant-picker-time-panel-cell {
    display: none;
  }

  .ant-modal-footer button {
    font-weight: bold;
  }

  .ant-modal-footer .ant-btn-primary {
    background: var(--sura-text-color);
    color: white;
    font-weight: bold;
  }

  .modal-backdrop.fade {
    opacity: 1 !important;
  }

  .modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
  }

  .user-favourite-shop-row>.col-lg-4{
    display: flex;
    flex-direction: column;
  }
  .user-favourite-shop-row>.col-md-6 {
    display: flex;
    flex-direction: column;
  }

  .shop-cart-dd {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  @media (max-width:575px) {
    .review-shop-rating-web{
      display: none !important;
    }
    .user-text-review>p,.shop-card-address {
      text-align: center !important;
    }
  }