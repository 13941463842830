.saved-address-item {
  border: none;
}

.saved-address-item .saved-address-header .accordion-button {
  padding: 0px !important;
}

.saved-address-body {
  outline: 1px solid gainsboro;
}

.saved-address-section {
  /* background-color: whitesmoke; */
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.saved-address-section:hover {
  color: var(--sura-text-color);
}
.saved-address-card{
  margin: 0px 10px;

}
.saved-address-outer{
  max-height: 17vh;
  height: 17vh;
  overflow: auto;
}
.saved-address-outer::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.saved-address-outer::-webkit-scrollbar-thumb{
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.saved-address-outer::-webkit-scrollbar-thumb:hover{
  background-color: var(--sura-text-color);
}

.saved-address-outer::-webkit-scrollbar-track{
  background-color: var(--scrollbar-color);
}

.saved-address-content{
  font-size: 15px;
  display: -webkit-box; /* Flex container for Webkit */
  -webkit-line-clamp: 2; /* Limit the text to 2 lines */
  -webkit-box-orient: vertical; /* Set box orientation to vertical */
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Add ellipses (...) at the end */
}
.saved-address-section-outer {
  padding: 5px 10px;
}
.saved-address-section-icon{
  display: flex;
  justify-content: flex-end;
}

.saved-address-section-icon .bi:hover {
  color: var(--sura-text-color);
}

.saved-address-section-icon i {
  font-size: 15px;
  font-weight: bold;
}

.saved-address-item .accordion-body {
  padding-top: 2px !important;
}

.saved-address-header .accordion-button:not(.collapsed){
  box-shadow:none !important;
}