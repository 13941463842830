.headerLine {
    border-bottom: none !important;
}
#submitButton {
    background: linear-gradient(var(--sura-text-color), var(--custom-link)) !important;
    color: var(--white-text-color) !important;
    font-size: 14px;
    margin-top: 20px;
}
.input-field-suggestion-modal{
    border-radius: 6px !important;
}
.customModal {
    width: 400px !important;
    margin: auto !important;
    top: 50% !important;
    border: none !important;
    transform: translateY(-50%) !important;
}
.suggestion-service-modal-formItem{
    padding: 0px 20px;
}
@media only screen and (max-width: 768px) {
    .customModal {
        width: 300px !important;
        margin: auto !important;
        top: 35% !important;
        border: none !important;
        transform: translateY(-50%) !important;
    }
}
@media only screen and (max-width: 285px) {
    .customModal {
        width: 250px !important;
        margin: auto !important;
        top: 35% !important;
        border: none !important;
        transform: translateY(-50%) !important;
    }
    #submitButton {
        background: linear-gradient(var(--sura-text-color),var(--custom-link)) !important;
        color: var(--white-text-color) !important;
        font-size: 13px;
        margin-top: 0px;
    }
}