@import "../../../../assets/styles/style.css";
:root {
  --font-base: "Lato";
  --fs-700: 2.986rem;
  --fs-600: 2.488rem;
  --fs-500: 2.074rem;
  --fs-400: 1.728rem;
  --fs-300: 1.44rem;
  --fs-200: 1.2rem;
  --fs-100: 1rem;
  --fs-50: 0.833rem;

  --white: #ffffff;
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  --grey-950: #020617;

  --primary: #006ed8;
  --primary-50: hsl(209, 100%, 52%);

  --text: var(--grey-500);
  --text-alt: var(--grey-900);
  --background: var(--grey-200);
  --background-alt: var(--grey-100);
  --background-shade: var(--grey-100);
}
@media (prefers-color-scheme: dark) {
  :root {
    --text: var(--grey-500);
    --text-alt: var(--grey-100);
    --background: var(--grey-900);
    --background-alt: var(--grey-800);
    --background-shade: var(--grey-700);
  }
}
h1,
h2,
h3,
h5,
h6 {
  line-height: 1.15;
  font-weight: 700;
}
.edit-booking-header {
  border-bottom: 1px solid #D9D9D9;
}
.title {
  margin-top: unset;
  margin-bottom: 1rem;
  text-align: center;
}
.form-row {
  margin-bottom: -8px;
}
.form-group {
  flex-grow: 1;
  flex-shrink: 1;
}
.form-input-booking {
  color: var(--text-alt);
  border: 0;
  box-shadow: 0 0 1px var(--text);
  height: 2.6em;
  width: 100%;
  margin-top: 5px;
  padding: 5px;
}
.form-input-booking-date{
  height: 2.8em;
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  border-radius: 0px;
}

.form-submit-booking,
.form-cancel-booking {
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 41px;
  width: 120px;
  padding-top: 0px;
}
.form-submit-booking {
  background: var(--bg-color);
  color: var(--white);
}
.form-cancel-booking {
  margin-right: 10px;
  background:var(--white-color);
  color:var(--black-color);
  border: 1px solid var(--warning-container-border);
}
.form-submit-booking:hover {
  background-color: var(--primary-50);
}
.custom-estric {
  margin-left: 2px;
  color: var(--red-color)!important;
}

.ant-modal-content {
  width: 85%;
  margin-left: 3em;
}

.btn-close {
  margin-left: 350px;
  margin-top: -20px;
}
.error {
  padding-bottom: 1px;
  margin-bottom: 1px;
}
.vendor-edit-ant-select-form{
  width: 100% !important;
  height: 2.7em !important;
}
.vendor-edit-ant-select-form .ant-select-selector{
  border-radius: 0px !important;
}
.form-input:focus {
  border-color: var(--warning-container-border) !important;
}