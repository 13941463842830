.header2 {
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
  box-shadow: var(--header-box-shadow) 0px 2px 8px 0px;
  background-color:var(--white-btn-bg-color);
}

/* ---------------------Apply Anim ation-------------------------- */
.nav-up {
  top: -50px;
  display: none;
}

/* ---------------------Apply Anim ation-------------------------- */

.menu-item-has-children.active .category {
  color: var(--category-hover-color) !important;
}

.header-item>.menu {
  display: block;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.header2 .item-left {
  flex: 0 0 17%;
}

.colorBlack {
  color: black;
}

.colorWhite {
  color: white;
}

.header2 .item-center {
  flex: 0 0 66%;
}

.header2 .item-right {
  flex: 0 0 17%;
  display: flex;
  justify-content: flex-end;
}
@media (min-width:992px) {
  .mobile-view-sub-category-list{
    display: none;
  }
}
@media (max-width:991px) {
  
  .menu-item-has-children>a {
    font-size: 14px;
    font-weight: 400;
    color: var(--navber-color);
    position: relative;
    text-transform: uppercase;
    transition: color 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Arial';
    white-space: nowrap;
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.header2 .item-right a {
  text-decoration: none;
  font-size: 16px;
  color: #555555;
  display: inline-block;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.header2 .menu>ul>li {
  display: inline-block;
  line-height: 45px;
  margin-left: 25px;
}

.header2 .menu>ul>li>a {
  font-size: 14px;
  font-weight: 400;
  color: var(--navber-color);
  position: relative;
  text-transform: uppercase;
  transition: color 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Arial';
}

.header2 .menu>ul>li>a:hover {
  color: var(--category-hover-color);
}

.menu-item-has-children>a>.bi-chevron-down {
  font-weight: bold;
  font-size: 15px;
}

.bi-chevron-down {
  font-size: 12px;

}

.CustomDropDownCategories-nav {
  min-height: 45px;
}

.header2 .menu>ul>li.menu-item-has-children:hover .sub-menu {
  margin: 0px;
  border-radius: 0px;
  border: 1px solid #EFEFEF
}

.header2 .menu>ul>li.best-seller:hover .sub-menu {
  margin: 0px -15px 0px !important;
  /* Adjust this value as needed */
}


.header2 .menu>ul>li .sub-menu {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--white-btn-bg-color);
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.header2 .menu>ul>li.best-seller:hover .sub-menu {
  margin: 0px -215px 0px;
}

.single-column-menu ul {
  list-style-type: none;
  padding: 0px;
  max-height: 75vh;
  overflow-y: auto;
}

.single-column-menu ul::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.single-column-menu ul::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color) !important;
  border-radius: 1em !important;
}

.single-column-menu ul::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color) !important;
}

.single-column-menu ul::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color) !important;
}

.menu-item-has-children {
  padding: 0px 10px;
}

.header-item>.menu::-webkit-scrollbar,
.review::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.header-item>.menu::-webkit-scrollbar-thumb,
.review::-webkit-scrollbar-thumb {
  background-color: var(--sura-text-color);
  border-radius: 1em;
}

.header-item>.menu::-webkit-scrollbar-thumb:hover,
.review::-webkit-scrollbar-thumb:hover {
  background-color: var(--sura-text-color);
}

.header-item>.menu::-webkit-scrollbar-track,
.review::-webkit-scrollbar-track {
  background-color: var(--scrollbar-color);
}


@media (min-width: 768px) {
  .header2 .menu>ul>li.best-seller:hover .sub-menu {
    margin: 0px -150px 0px;
    /* Adjust this value as needed */
  }
}

@media (min-width: 1024px) {
  .header2 .menu>ul>li.best-seller:hover .sub-menu {
    /* margin: 0px -93px 0px !important;  */
    margin: 0px -47px 0px !important;
  }
}

@media(min-width: 992px) {
  .header2 .menu>ul>li.menu-item-has-children:hover .sub-menu {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }

  .header2 .menu>ul>li.menu-item-has-children:hover {
    border-radius: 0px;
    color: var(--category-hover-color);
    /* padding: 0px 30px; */
  }

  .header2 .menu>ul>li.menu-item-has-children {
    border-radius: 0px;
    /* padding: 0px 30px; */
  }

  .down-arrow-bi {
    font-size: 16px !important;
  }

  .menu-item-has-children:hover {
    border-radius: 0px;
    color: var(--category-hover-color);
  }
}

.header2 .menu>ul {
  margin-bottom: 0px !important;
}

.header2 .menu>ul>li .sub-menu>ul>li {
  line-height: 1;
  white-space: nowrap;
}

.single-column-menu:active {
  width: 23pc;
  color: var(--category-hover-color);
}

.header2 .menu>ul>li .sub-menu>ul>li>a {
  display: inline-block;
  font-size: 16px;
  color: #555555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out !important;
  line-height: 20px;
  min-width: 140px;
  max-width: 100%;
  line-height: 40px;
  height: 40px;
  padding: 0 15px 0 15px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header2 .menu>ul>li .sub-menu>ul>li>a:hover {
  color: var(--category-hover-color);
}

.header2 .menu>ul>li .single-column-menu {
  /* min-width: 220px; */
  min-width: auto;
  max-width: max-content !important;
}

.disable-go-back {
  opacity: 0.3;
  pointer-events: none;
}

.menu-sidebar-header-section .mobile-menu-head,
.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

@media(max-width: 421px) {

  .footer .box {
    text-align: center
  }


}

@media(max-width: 991px) {
  .header2 .item-center {
    order: 3;
    flex: 0 0 100%;
  }

  .header2 .item-left,
  .header2 .item-right {
    flex: 0 0 auto;
  }

  .v-center {
    justify-content: space-between;
  }

  .header2 .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .header2 .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #333333;
    width: 24px;
    position: relative;
  }

  .header2 .mobile-menu-trigger span:before,
  .header2 .mobile-menu-trigger span:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333;
  }

  .header2 .mobile-menu-trigger span:before {
    top: -6px;
  }

  .header2 .mobile-menu-trigger span:after {
    top: 6px;
  }

  .header2 .item-right {
    align-items: center;
  }

  .header2 .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease !important;
    z-index: 1099;
  }

  .header2 .menu.active {
    transform: translate(0%);
  }

  .header2 .menu>ul>li {
    line-height: 1;
    margin: 0;
    display: block;
  }

  .header2 .menu>ul>li>a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header2 .menu>ul>li>a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }

  .menu-sidebar-header-content,.menu-sidebar-header-content {
    display: none !important;
  }

  .header2 .menu .mobile-menu-head,
  .menu-sidebar-header-section .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background: linear-gradient(254.46deg, var(--sura-text-color) 7.39%, #FD6266 88.94%);
    ;
    top: 0;
  }

  .header2 .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
  }

  .header2 .menu .mobile-menu-head.active .go-back {
    display: block;
  }

  .header2 .menu .mobile-menu-head .current-menu-title {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
  }

  .header2 .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: white;
    font-size: 25px;
    transition: all 0.5s ease !important;

  }

  .mobile-menu-head>.go-back>.bi-arrow-left {
    color: white;
  }

  .header2 .menu .menu-main {
    padding-left: 0px !important;
  }

  .header2 .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .header2 .menu>ul>li .sub-menu.mega-menu,
  .header2 .menu>ul>li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
  }

  .header2 .menu>ul>li .single-column-menu {
    max-width: unset !important;

  }

  .header2 .menu>ul>li .sub-menu.active {
    display: block;
  }

  .header2 .menu>ul>li.menu-item-has-children:hover .sub-menu {
    border: none;
    width: 350px;
  }

  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width:422px) {
  .mobile-view-search-fild-outer-div {
    display: none;
  }
}

@media (max-width:421px) {
  .mobile-view-search-fild-outer-div {
    display: flex;
    align-items: center;
    padding: 4px;
  }

  .header2 .item-right {
    justify-content: space-around;
    flex: auto !important;
    display: flex;
  }
}

@media (max-width:322px) {
  .header2 .menu {
    width: 100%;
  }
}

.user-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}