 .table-heading {
   background-color: grey !important;
   color: white;
 }
 .branch-td-class {
   text-align: left;
 }
 .bootTable-action-td {
   cursor: pointer;
 }
 .table-heading {
   border-radius: 20px !important;
 }
 .table-heading th {
   font-weight: bold;
 }
 .table-heading {
   background-color: black;
   border-radius: 10px !important;
 }
 .thclass-width{
  width: 21%;
 }
 .thclass-width14{
  width: 14%;
 }
 .thclass-width9{
  width: 9%;
 }
 .thclass-width15{
  width: 15%;
 }
 .status-button {
   width: 33px;
   height: 17px;
   border-radius: 25px;
   border: 1px solid var(--sura-text-color);
   background: linear-gradient(var(--sura-text-color), #FD6266);
 }
 .boot-table-filter {
   padding: 5px 10px 0px 0px !important;
   background-color: var(--icon-color);
 }
 .boot-table-filter-image {
   cursor: pointer;
   height: 20px;
   width: 30px;
 }
 .branches-table {
   position: relative;
   display: inline-block;
 }
 .branches-table i {
   position: absolute;
   left: 10px;
   top: 50%;
   transform: translateY(-50%);
   color: #555;
 }
 .branches-table-top-heading {
   text-align: center;
   height: 5vh;
 }
 .branches-table-thclass {
   text-align: left;
   font-weight: 600;
 }
 .branch-table-action-outer-div {
   justify-content: center;
 }
 @media only screen and (max-width: 820px) {
   .branches-table-top-heading {
     text-align: center;
     height: 5vh;
   }
   .branches-table-thclass {
     font-weight: 600;
     padding: 0px !important;
   }
 }
 @media only screen and (max-width: 600px) {
   .branches-table-thclass {
     font-weight: 420;
     padding: 14px !important;
   }
 }
 .branch-filter-input-feild-div {
   position: relative;
 }
 .filter-input-feild {
   background-color: #F8F8F8;
   height: 2.3em;
   padding: 0 30px;
   border: 1px solid #D9D9D9;
   border-radius: 5px;
   width: 100%;
   max-width: 130px;
   margin-top: 2px;
 }
 .filter-input-feild:focus {
   outline: none;
   border-color: #D9D9D9;
 }
 .branch-table-tbody-tr-tag {
   text-align: center;
 }
 .filter-select-branch {
   width: 100%;
   margin-top: 2px;
   height: 2.5em;
 }