.add-to-favoutite-shop-button-mobile {
    display: none;
}

.display-service-heading:hover {
    color: var(--sura-text-color);
}

@media (max-width:575px) {
    .cart-name-start-time-child>.favourite-service-rating-address>.favorite-service-clock {
        margin-left: 10px;
    }

    .cart-name-start-time-child>.favourite-service-rating-address>.favourite-service-time {
        margin-left: 2px;
    }

    .favourite-service-price {
        display: flex;
        justify-content: center;
    }

    .favourite-service-price>.service-card-price2-new {
        display: flex;
        justify-content: center;
    }
}

@media (max-width:449px) {
    .favourite-service-rating-address {
        display: flex;
        justify-content: center;
    }

    .price-child {
        display: flex;
        justify-content: left;
    }

    .add-to-favoutite-shop-button {
        display: none !important;
    }

    .add-to-favoutite-shop-button-mobile {
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }

    .favourite-shop-price {
        display: flex !important;
        justify-content: center !important;
    }

    .cart-name-start-time-child>.favourite-service-rating-address>.favorite-service-clock {
        margin-top: 2px;
    }

    .cart-name-start-time-child>.favourite-service-rating-address>.favourite-service-time {
        margin-top: 2px;
    }
}