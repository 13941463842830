.payment-fail-continer {
    text-align: center;
    padding: 40px 0;
  }

.payment-fail-page{
    border-radius: 200px;
    height: 200px;
    width: 200px;
    margin: 0 auto;

}
.payment-fail-continer button{
    border-radius: 0px!important;
}
.payment-fail-continer h1 {
    color: #dc3545;
    font-family: "Arial";
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
 .payment-fail-continer p {
    color: #404F5E;
    font-family: "Arial";
    font-size:20px;
  }
.payment-fail-continer img {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
}
.payment-fail-continer .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}
@media (max-width:320px){
    .payment-fail-continer .card{
        padding: 40px;
    }
}