@import "../../assets/styles/style.css";

.useredit-container .ant-picker-outlined {
  height: 2.5em;
}
.ant-select-selector{
  border-radius: 6px!important;
}

.finace-ant-select-form {
  text-transform: capitalize;
}
.circle-background-my-profile {
  position: relative;
  top: 88px;
  left: 49px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.finace-ant-select-form {
  cursor: pointer !important;
}


.user-profile-outer-div {
  padding-left: 22px;
  padding-right: 10px;
}

.useredit-container input,
.useredit-container select {
  border-radius: 0;
}

::placeholder {
  font-size: 14px;
}

.useredit-container .useredit-deletebtn {
  width: 200px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid;
  color: var(--card-btn-color);
  background-color: var(--white-btn-bg-color);
}

.calender-icon {
  cursor: pointer;
}

.avatar {
  vertical-align: middle;
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.useredit-container h5 {
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
}

.useredit-profilebox .card {
  height: 230px;
  flex-shrink: 0;
  border-radius: 10px;
  text-align: center;
}

.useredit-profilebox h5 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.44px;
  text-transform: capitalize;
}


.icon {
  margin-right: 5px;
}


.useredit-form {
  left: 0;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
}

.useredit-container .icon {
  position: absolute;
  margin-top: -4% !important;
  margin-left: 50% !important;
  width: 19.252px;
  height: 19.252px;
  filter: var(--filter-drop-shadow);
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.useredit-container .icon::before {
  cursor: pointer;
  content: "+";
  color: var(--black-color);
}

.date-picker-container {
  position: relative;
}

.rmdp-input {
  height: 37px !important;
  width: 410px !important;
}

@media (max-width: 1200px) {
  .useredit-profilebox {
    height: auto;
  }
}

@media (max-width: 996px) {
  .useredit-container .useredit-deletebtn {
    width: 70% !important;
    margin-left: -20% !important;
  }

  .rmdp-input {
    height: 37px !important;
    width: 26vw !important;
  }

}

@media (max-width: 870px) {
  .useredit-container .useredit-deletebtn {
    font-size: 15px;
  }

  .useredit-form label {
    font-size: 15px;
  }

  .rmdp-input {
    height: 37px !important;
    width: 26vw !important;
  }
}

@media (max-width: 670px) {
  .rmdp-input {
    height: 37px !important;
    width: 53vw !important;
  }
}

@media (max-width:767px) {
  .user-profile-form-input-common {
    margin-top: 1vh;
  }
}

.row {
  --bs-gutter-x: 1rem;
}

.userprofileform-save-button {
  color: var(--white-text-color);
  background: var(--bg-color);
  border: none!important;
}

.userprofileform-cancel-button {
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid;
  color:var(--card-btn-color);
  background-color: var(--white-btn-bg-color);
}

.userprofileform-cancel-button:hover {
  color:var(--card-btn-color) !important;
  border: 1px solid;
  background-color: var(--white-btn-bg-color);
}

@media (max-width:767px) {
  .useredit-profilebox .card {
    height: auto;
  }
}

.user-profile-date-picker-ant {
  border-radius: 6px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}

.breadcrumb-link-tag {
  cursor: pointer;
  font-size: 14px;
color: var(--review-rating-active-btn) !important;
  text-decoration: none;
}
.finace-IntlTelInput-form .separate-dial-code input{
  height: 2.2rem;

}
.breadcrumb-link-tag:hover {
  text-decoration: underline;

}

.breadcrumb ol {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 1em;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumb li {
  display: flex;
  align-items: center;
}

.breadcrumb li:not(:last-child):after {
  content: '';
}

.breadcrumb-separator {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 0.5em;
  color: var(--bread-com);
}

.breadcrumb a {
  font-size: 14px;
  color: var(--black-color);
  transition: color 0.3s;
}

.breadcrumb-li-tag {
  font-size: 14px;
}

.user-profile-date-picker-ant {
  border-radius: 6px;
}

.user-my-profile-lable {
  font-size: 15.5px;
}

.user-profile-h5-tag {
  font-size: 16px !important;
}

.card {
  background: var(--white-btn-bg-color);
  border-radius: 4px;
  box-shadow: 0px 1px 10px 1px var(--payout-summary-daily-transaction-inner-box-shadow);
}

.my-profile-avtar-img {
  height: 125px !important;
  width: 125px !important;
}

.useredit-form .card-body {
  padding: 10px;
}

.user-profile-field-outer .ant-space {
  display: inline;
}

.useredit-container {
  min-height: 60vh;
}

.changePassword_new_password_Div label {
  font-size: 15px;
}

.user-profile-field-outer {
  margin-top: 3px;
}

.user-profile-field-outer .ant-space .ant-space-item .ant-input-affix-wrapper {
  border-radius: 6px;
}

@media (max-width:440px) {
  .my-profile-section>.user-bookings-tab {
    font-size: 11px !important;
  }
}

@media (max-width:333px) {
  .my-profile-section>.my-profile-change-password {
    margin-top: 10px;
  }
}

@media (max-width:992px) and (min-width:575px) {
  .my-profile-avtar-img {
    width: 100px !important;
    height: 100px !important;
  }
}

@media (max-width:575px) {
  .my-profile-avtar-img {
    width: 100px !important;
    height: 100px !important;
  }

}
@media (max-width:991px) {
.circle-background-my-profile {
  position: relative;
  top: 66px;
  left: 40px;
  width: 20px;
  height: 20px;
  background-color: var(--white-btn-bg-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--circle-box-shadow);
}
}



.circle-background-my-profile .bi {
  font-size: 13px !important;
  font-weight: bold !important;
  color: var(--sura-text-color);
}

@media (max-width:575px) {
  .user-my-profile>.col-sm-9>.card {
    margin: 0px !important;
  }

  .team-member-working-hour {
    margin-top: 5px;
  }

  .useredit-container>.mt-3 {
    margin-top: 1rem !important;
  }
}

.my-profile-avtar-img {
  position: absolute;
}

.edit-profile>.sc-aXZVg {
  opacity: 0;
  display: block;
  top: 43px;
  left: 23px;
  align-items: center;
  min-width: unset;
  max-width: unset;
  border: 2px dashed rgb(6, 88, 194);
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
  height: 125px;
  width: 125px;
  border-radius: 50% ;
}

.useredit-profilebox .vendor-team-list-modal>.sc-aXZVg{
  opacity: 0;
  display: block;
  top: 85px;
  left: 50px;
}

.useredit-profilebox>.card>.card-body>.edit-profile>.avatar-edit {
  position: absolute;
}

@media (max-width:768px) {
  .useredit-container>.mt-3{
    margin-top: 0px !important;
  }
  .user-my-profile>.useredit-profilebox>.card{
      margin:1vh auto 1vh auto !important ;
  }
  .user-my-profile>div>.card{
    margin:1vh auto 1vh auto !important ;
  }
}